import { NeosBookingId } from '@/neos/components/share/NeosBookingId/NeosBookingId';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { useDispatch } from 'react-redux';
import { thunks } from '@/bootstrap/thunks';
import type { ElsLendAndBorrowGridRowProps } from '@/neos/components/rfq/strategies/strategy/features/elsLendAndBorrow/ElsLendAndBorrowGridRow';

export function TppBookingId({
  stockLoanComponent,
  product,
  index,
  rfqId,
}: ElsLendAndBorrowGridRowProps) {
  const dispatch = useDispatch();
  const tppBookingInfo = useAppSelector(state =>
    selectors
      .selectBookingStepsByFilter(state, {
        referenceUuid: stockLoanComponent.uuid,
        rfqId,
        stepType: 'XONE_HEDGE',
        type: 'HEDGE',
      })
      .at(0),
  );

  function onTppBookingIdChanged(id: string | undefined) {
    dispatch(
      thunks.neos.createUpdateElsStockLoanComponentThunk(
        product.uuid,
        index,
        'bookingApplication',
        id === undefined ? undefined : 'XONE',
      ),
    );
    dispatch(
      thunks.neos.createUpdateElsStockLoanComponentThunk(product.uuid, index, 'bookingId', id),
    );
  }

  return (
    <NeosBookingId
      defaultBookingId={tppBookingInfo?.bookingId}
      bookingId={stockLoanComponent.bookingId ?? tppBookingInfo?.bookingId}
      dataE2e={`els-stockLoanComponent-pttId-${tppBookingInfo?.bookingId}`}
      bookingStatus={
        stockLoanComponent.bookingId === undefined ? tppBookingInfo?.status : undefined
      }
      bookingTimeStamp={
        stockLoanComponent.bookingId === undefined ? tppBookingInfo?.lastUpdateTime : undefined
      }
      message={stockLoanComponent.bookingId === undefined ? tppBookingInfo?.message : undefined}
      onBookingIdChanged={onTppBookingIdChanged}
    />
  );
}
