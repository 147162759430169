import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

export function createUpdateValueDateOffsetThunk(
  rfqId: string,
  strategyId: string,
  offset: number,
): Thunk {
  return function updateValueDateOffsetThunk(
    dispatch,
    getState,
    { thunks, actionCreators: { neos: as }, selectors },
  ) {
    const state = getState();

    const elsFeatureToggleEnabled = selectors.isFeatureToggleEnabled(
      state,
      'neos.els.update.enabled',
    );
    const isEls = elsFeatureToggleEnabled && selectors.isElsStrategy(state, strategyId, selectors);

    const defaultingOptions = new DefaultingOptions({
      enrichScopes: [
        DefaultingScope.VALUE_DATE,
        DefaultingScope.SETTLEMENT_OTC,
        DefaultingScope.EFFECTIVE_DATE,
      ],
      overrideScopes: isEls ? [DefaultingScope.FIXED_DAY_TYPE] : [],
    });

    const products = selectors.getStrategyProducts(state, strategyId, selectors);

    products.forEach(product =>
      dispatch(thunks.neos.changeClsIsScheduleObsoleteThunk(rfqId, product.uuid, true)),
    );

    dispatch(
      as.strategyDataCrudActions.update(strategyId, {
        valueDate: { value: undefined, offset },
      }),
      as.createDefaultRfqRequestedAction(rfqId, defaultingOptions),
    );
  };
}
