import { type ActionCreators, actionCreators } from '@/bootstrap/actions';
import type { ThunkEpic } from '@/bootstrap/epics';
import { type Dispatchable, type Thunks, thunks } from '@/bootstrap/thunks';
import type { SgmeHttp } from '@/util/http/sgmeHttpBase';
import { logger } from '@/util/logging/logger';
import { mt } from '@/util/logging/messageTemplates';
import { identity } from 'lodash';
import { ofType } from 'redux-observable';
import type { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { type Selectors, selectors as allSelectors } from '../../../../bootstrap/selectors';
import { wrapInChainableLoadingObservable } from '../../epics/wrapInChainableLoadingObservable';
import { type ToOnyxMappers, mappers } from '../../mappers';
import { createDefaultNegotiationApi } from '../apis/defaultingApi';
import type { DefaultingOptionsParameters } from '../rfqActions';
import type { OnyxRfq } from '../rfqOnyxModel';

export type RequiredDefaultingOptionsParameters = Required<
  OmitSafe<DefaultingOptionsParameters, 'isSettlementOtcOverride'>
> &
  Pick<DefaultingOptionsParameters, 'isSettlementOtcOverride'>;

export interface DefaultRfqApi {
  defaultRfq: (
    rfq: OnyxRfq,
    defaultingOptionsParameters: RequiredDefaultingOptionsParameters,
  ) => Observable<OnyxRfq>;
}

export function getDefaultRfqEpic(http: SgmeHttp): ThunkEpic {
  const api = createDefaultNegotiationApi(http);
  return createDefaultRfqEpic(api, allSelectors, thunks, mappers.toOnyxMappers, actionCreators);
}

export function createDefaultRfqEpic(
  api: DefaultRfqApi,
  selectors: Selectors,
  { neos: { createDefaultRfqThunk }, createErrorToasterThunk, createWarningToasterThunk }: Thunks,
  toOnyxMappers: ToOnyxMappers,
  { common: { createAppCrashedAction }, neos: { createDefaultRfqRequestedAction } }: ActionCreators,
): ThunkEpic {
  return (action$, state$) =>
    action$.pipe(
      ofType('DEFAULT_RFQ_REQUESTED'),
      mergeMap(({ rfqId, options, chainOptions, retryOnErrorOptions }) => {
        let rfq: OnyxRfq;
        try {
          rfq = toOnyxMappers.mapToOnyxRfqWithCurrentLocalVersion(
            state$.value,
            rfqId,
            selectors,
            toOnyxMappers,
          );
        } catch (error) {
          logger.error(mt.unhandledException, 'Mapping error when defaulting RFQ', error);
          return [createErrorToasterThunk({ message: 'Error when defaulting RFQ!' }, undefined)];
        }
        const {
          overrideScopes,
          overrideIfInvalidScopes,
          enrichScopes,
          strategiesFilterer = identity,
        } = options;
        return wrapInChainableLoadingObservable({
          tabIds: [rfqId],
          apiObservable: api.defaultRfq(rfq, {
            overrideScopes,
            overrideIfInvalidScopes,
            enrichScopes,
            isSettlementOtcOverride: false,
          }),
          withRetryOnError: retryOnErrorOptions && retryOnErrorOptions.retries > 0,
          observableOptions: {
            success: {
              on: defaultedRfq => {
                return [createDefaultRfqThunk(defaultedRfq, options, strategiesFilterer)];
              },
            },
            retryOnError: {
              on: _error => {
                if (retryOnErrorOptions) {
                  const retryActions: Dispatchable[] = [
                    createWarningToasterThunk({
                      message: 'RFQ defaulting is longer than usual, please wait',
                    }),
                  ];
                  if (retryOnErrorOptions.retries > 0) {
                    retryActions.push(
                      createDefaultRfqRequestedAction(rfqId, options, chainOptions, {
                        ...retryOnErrorOptions,
                        retries: retryOnErrorOptions.retries - 1,
                      }),
                    );
                  }

                  return retryActions;
                }

                return [];
              },
            },
            error: {
              toaster: {
                creator: (message, error) => createErrorToasterThunk({ message }, error),
                message: 'Error when defaulting RFQ!',
              },
            },
          },
          chainOptions,
        });
      }),
      catchError(error => [createAppCrashedAction('default-rfq-epic', error)]),
    );
}
