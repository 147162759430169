import { exportDataToExcel } from '@/util/excel/excel';
import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type {
  LegPeriod,
  LegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { useSelector } from 'react-redux';

type ScheduleExportProps = {
  strategyId: string;
};

export const ELSScheduleExport = ({ strategyId }: ScheduleExportProps) => {
  const equityData = useSelector((state: AppState) =>
    selectors.selectScheduleEquityLegDates(state, strategyId, selectors),
  );

  const rateData = useSelector((state: AppState) =>
    selectors.selectScheduleRateLegDates(state, strategyId, selectors),
  );

  const dividendData = useSelector((state: AppState) =>
    selectors.selectScheduleDividendLegDates(state, strategyId, selectors),
  );

  function transformDataForExport() {
    const maxLength = [rateData, dividendData].reduce<number>(
      (previousLength, currentArray) =>
        currentArray && currentArray.length > previousLength ? currentArray.length : previousLength,
      equityData?.length ?? 0,
    );

    const exportedData = [];
    for (let index = 0; index < maxLength; index++) {
      const newLine = {
        'Equity Start Date': getCellValue(index, equityData, 'startDate'),
        'Equity End Date': getCellValue(index, equityData, 'endDate'),
        'Equity Pay Date': getCellValue(index, equityData, 'paymentDate'),

        'Rate Fixing Date': getCellValue(index, rateData, 'fixingDate'),
        'Rate First Fixing Date': getCellValue(
          index,
          rateData,
          'firstFixingDate' as keyof LegPeriodDates,
        ),
        'Rate Start Date': getCellValue(index, rateData, 'startDate'),
        'Rate End Date': getCellValue(index, rateData, 'endDate'),
        'Rate Pay Date': getCellValue(index, rateData, 'paymentDate'),

        'Dividend Start Date': getCellValue(index, dividendData, 'startDate'),
        'Dividend End Date': getCellValue(index, dividendData, 'endDate'),
        'Dividend Pay Date': getCellValue(index, dividendData, 'paymentDate'),
        'Dividend Theoretical Payment Date': getCellValue(
          index,
          dividendData,
          'theoreticalPeriodPaymentDate',
        ),
      };
      exportedData.push(newLine);
    }
    return exportedData;

    function getCellValue<T extends LegPeriodDates>(
      index: number,
      data: LegPeriod<T>[] | undefined,
      key: keyof T,
    ) {
      return data && data.length > index ? data[index].dates[key] : '';
    }
  }

  const isButtonDisabled = !rateData?.length && !equityData?.length && !dividendData?.length;

  return (
    <button
      data-e2e="els-schedule-export"
      className={`btn btn-discreet-primary sgbs-btn-primary ${isButtonDisabled && 'disabled'}`}
      onClick={() =>
        !isButtonDisabled && exportDataToExcel(transformDataForExport(), 'Els Schedule')
      }
      disabled={isButtonDisabled}
    >
      Export <i className="icon icon-sm ms-2">file_download</i>
    </button>
  );
};
