import type {
  AllStrategyColumns,
  ColumnGridDefinition,
} from '@/neos/components/rfq/strategies/getDisplayedColumns';
import { Fragment } from 'react';
import { AccrualCells } from './accrualCells';
import { ClientPositionCells } from './clientPositionCells/ClientPositionCells.container';
import { ClsTypeCells } from './clsTypeCells';
import { EffectiveDateCells } from './effectiveDateCells/effectiveDateCells';
import { ElsTypeCells } from './elsTypeCells';
import { ExpectedNCells } from './expectedNCells';
import { ForwardDriftCells } from './forwardDriftCells';
import { FutureMaturityCells } from './futureMaturityCells/FutureMaturityCells.container';
import { InterestRateCells } from './interestRateCells';
import { IsMasterLegCells } from './isMasterLegCells';
import { LegLinkCells } from './legLinkCells/LegLinkCells.container';
import { LotSizeMarketCell } from './lotSizeMarketCell/LotSizeMarketCell.container';
import { LowerStrikeCells } from './lowerStrikeCells';
import { MaturityCells } from './maturityCells';
import { NegoTypeCell } from './negoTypeCell';
import { OptionFlexCells } from './optionFlexCells/OptionFlexCells.container';
import { OptionStyleCells } from './optionStyleCells';
import { OptionTypeCells } from './optionTypeCells';
import { RatioCells } from './ratioCells';
import { SecondaryTypeCell } from './secondaryType/secondaryTypeCell/SecondaryTypeCell.container';
import { SecondaryTypeWidgetCells } from './secondaryType/secondaryTypeCells/SecondaryTypeCells';
import { SizeCells } from './sizeCells';
import { StrategyLinkCell } from './strategyLinkCell/StrategyLinkCell.container';
import { StrategyTypeCell } from './strategyTypeCell';
import { StrikeCells } from './strikeCells';
import { StrikeDateCells } from './strikeDateCells/StrikeDateCells';
import { SwapCurrencyCells } from './swapCurrencyCells';
import { UnderlyingCells } from './underlyingCells';
import { UpperStrikeCells } from './upperStrikeCells';
import { WarningCells } from './warningCells/WarningCells';
import { WeightCell } from './weightCell';

import { ClientTaxRateCells } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/ClientTaxRateCells/ClientTaxRateCells';
import { ObservableType } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/ObservableType/ObservableType';
import { BasketToggleCell } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/basketToggleCells/BasketToggleCell';
import { NoTaxCollectionCells } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/noTaxCollectionCells/NoTaxCollectionCells';
import styles from './StrategyDefinition.module.scss';
import { ScheduleCell } from './scheduleCells/ScheduleCell';
import { SettlementDateCells } from './settlementDateCells/settlementDateCells';
import { StartDateCell } from '@/neos/components/rfq/strategies/strategy/strategyDefinition/startDateCell/StartDateCell.tsx';

export interface StrategyDefinitionOwnProps {
  rfqId: string;
  strategyId: string;
  columnGridDefinition: ColumnGridDefinition[];
  gridTemplateColumns: string;
  isReadOnlyRfq: boolean;
  isSimilarActivityMode: boolean | undefined;

  onFocus(): void;
}

type StrategyDefinitionProps = StrategyDefinitionOwnProps;

export function StrategyDefinition({
  rfqId,
  strategyId,
  onFocus,
  columnGridDefinition,
  gridTemplateColumns,
  isReadOnlyRfq,
  isSimilarActivityMode,
}: StrategyDefinitionProps) {
  return (
    <section
      style={{
        gridTemplateColumns,
      }}
      className={`${styles['strategy-grid']} ${styles['strategy-definition']}`}
      onFocus={onFocus}
      onClick={onFocus}
      data-e2e="neos-strategy-definition"
    >
      {columnGridDefinition.map(columnDefinition => (
        <Fragment key={columnDefinition.id}>{makeColumnContent(columnDefinition)}</Fragment>
      ))}
    </section>
  );

  function makeUnderlyingColumn() {
    return <UnderlyingCells rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />;
  }

  function makeWeightColumn() {
    return <WeightCell rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />;
  }

  function makeSizeColumn() {
    return <SizeCells rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />;
  }

  function makeStrategyTypeColumn() {
    return <StrategyTypeCell rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />;
  }

  function makeRatioColumn() {
    return <RatioCells rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />;
  }

  function makeMaturityColumn() {
    return <MaturityCells rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />;
  }

  function makeEffectiveDateColumn() {
    return (
      <EffectiveDateCells rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />
    );
  }

  function makeStartDateColumn() {
    return <StartDateCell rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />;
  }

  function makeSettlementDateColumn() {
    return (
      <SettlementDateCells rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />
    );
  }

  function makeFutureMaturityColumn() {
    return (
      <FutureMaturityCells rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />
    );
  }

  function makeExpectedNColumn() {
    return <ExpectedNCells rfqId={rfqId} strategyId={strategyId} isReadOnlyRfq={isReadOnlyRfq} />;
  }

  function makeSecondaryTypeColumn() {
    return (
      <SecondaryTypeWidgetCells
        rfqId={rfqId}
        strategyId={strategyId}
        isSimilarActivityMode={!!isSimilarActivityMode}
      />
    );
  }

  function makeSecondaryTypeOnSecondaryRfqColumn() {
    return <SecondaryTypeCell rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeStrategyLinkColumn() {
    return <StrategyLinkCell rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeLegLinkColumn() {
    return <LegLinkCells rfqId={rfqId} strategyId={strategyId} legIds={[]} />;
  }

  function makeIsMasterLegColumn() {
    return <IsMasterLegCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeNoTaxCollectionColumn() {
    return <NoTaxCollectionCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeClientTaxRateColumn() {
    return <ClientTaxRateCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeClientPositionColumn() {
    return <ClientPositionCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeOptionStyleColumn() {
    return <OptionStyleCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeObservableTypeColumn() {
    return <ObservableType rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeOptionFlexColumn() {
    return <OptionFlexCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeOptionTypeColumn() {
    return <OptionTypeCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeLotSizeColumn() {
    return <LotSizeMarketCell rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeStrikeColumn() {
    return <StrikeCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeUpperStrikeColumn() {
    return <UpperStrikeCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeLowerStrikeColumn() {
    return <LowerStrikeCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeForwardInterestRateColumn() {
    return <InterestRateCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeForwardDriftColumn() {
    return <ForwardDriftCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeSwapCurrencyColumn() {
    return <SwapCurrencyCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeScheduleColumn() {
    return <ScheduleCell rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeBasketToggleColumn() {
    return <BasketToggleCell rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeStrikeDateColumn() {
    return <StrikeDateCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeElsTypeColumn() {
    return <ElsTypeCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeNegoTypeColumn() {
    return <NegoTypeCell rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeClsTypeColumn() {
    return <ClsTypeCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeAccrualTypeColumn() {
    return <AccrualCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeWarningsColumn() {
    return <WarningCells rfqId={rfqId} strategyId={strategyId} />;
  }

  function makeEmptyStrategyColumn() {
    return <div />;
  }

  function makeColumnComponent(strategyIds: string[], makeComponent: () => JSX.Element) {
    return isColumnAvailableForStrategy(strategyIds) ? makeComponent : makeEmptyStrategyColumn;
  }

  function isColumnAvailableForStrategy(strategyIds: string[]) {
    return strategyIds.find(strategyId => strategyId === strategyId);
  }

  function makeColumnContent({ id, strategyIds }: ColumnGridDefinition) {
    const columnComponentDefinition: { [columnId in AllStrategyColumns]: () => JSX.Element } = {
      warnings: makeWarningsColumn,
      weight: makeWeightColumn,
      size: makeSizeColumn,
      secondaryType: makeSecondaryTypeColumn,
      secondaryTypeOnSecondaryRfq: makeSecondaryTypeOnSecondaryRfqColumn,
      strategyLink: makeStrategyLinkColumn,
      legLink: makeLegLinkColumn,
      strategyType: makeStrategyTypeColumn,
      ratio: makeRatioColumn,
      isMasterLeg: makeIsMasterLegColumn,
      underlying: makeUnderlyingColumn,
      maturity: makeMaturityColumn,
      noTaxCollection: makeNoTaxCollectionColumn,
      clientTaxRate: makeClientTaxRateColumn,
      effectiveDate: makeEffectiveDateColumn,
      futureMaturity: makeFutureMaturityColumn,
      clientPosition: makeClientPositionColumn,
      expectedN: makeExpectedNColumn,
      optionStyle: makeOptionStyleColumn,
      observableType: makeObservableTypeColumn,
      optionFlex: makeOptionFlexColumn,
      optionType: makeOptionTypeColumn,
      strike: makeStrikeColumn,
      schedule: makeScheduleColumn,
      basketToggle: makeBasketToggleColumn,
      upperStrike: makeUpperStrikeColumn,
      lowerStrike: makeLowerStrikeColumn,
      swapCurrency: makeSwapCurrencyColumn,
      startDate: makeStartDateColumn,
      settlementDate: makeSettlementDateColumn,
      strikeDate: makeStrikeDateColumn,
      lotSize: makeLotSizeColumn,
      forwardDrift: makeForwardDriftColumn,
      forwardInterestRate: makeForwardInterestRateColumn,
      elsType: makeElsTypeColumn,
      negoType: makeNegoTypeColumn,
      clsType: makeClsTypeColumn,
      accrual: makeAccrualTypeColumn,
    };

    return makeColumnComponent(strategyIds, columnComponentDefinition[id])();
  }
}
