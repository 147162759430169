import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import type { ExtendedDispatch } from '@/bootstrap/thunks';
import { neosThunks } from '@/neos/business/thunks';
import { useDispatch, useSelector } from 'react-redux';
import {
  GlobalActionsComponent,
  type GlobalActionsDispatchProps,
  type GlobalActionsOwnProps,
  type GlobalActionsStateProps,
} from './GlobalActions';
import { getGlobalActionsModel } from './getGlobalActionsModel';
import { getIsPreConfToBeCompleted } from './getIsPreConfToBeCompleted';

export const GlobalActions = (ownProps: GlobalActionsOwnProps) => {
  const stateProps = useSelector<AppState, GlobalActionsStateProps>(state => ({
    model: getGlobalActionsModel(state, ownProps.rfqId, selectors),
    isPreConfToBeCompleted: getIsPreConfToBeCompleted(state, ownProps.rfqId, selectors),
  }));
  const dispatch = useDispatch<ExtendedDispatch>();
  const dispatchProps = getDispatchProps(dispatch, ownProps);
  return <GlobalActionsComponent {...ownProps} {...stateProps} {...dispatchProps} />;
};

function getDispatchProps(
  dispatch: ExtendedDispatch,
  { rfqId }: GlobalActionsOwnProps,
): GlobalActionsDispatchProps {
  return {
    addStrategies: (number: number) => {
      dispatch(neosThunks.createManyStrategiesAddedThunk(rfqId, number));
    },
  };
}
