import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxAlert } from '@/neos/business/alert/alertOnyxModel';

export function createAlertReceivedThunk(alert: OnyxAlert): Thunk {
  return function alertReceivedThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { createAlertCrudAction },
      },
      thunks: {
        neos: { createPlayMdpSoundThunk },
      },
    },
  ) {
    const action: Action = alert.active
      ? createAlertCrudAction.upsert(alert.uuid, alert)
      : createAlertCrudAction.delete(alert.uuid);

    dispatch(action, createPlayMdpSoundThunk(alert));
  };
}
