import type {
  LegPeriod,
  LegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { FlowDatePicker } from '../../../../../../share/datePicker';
import '../ElsClsSchedule.module.scss';
import { If } from '@/neos/components/share/if/if';

interface ScheduleLegProps {
  addLine: () => void;
  deleteLine: (uuid: string) => void;
  title: string;
  data: Array<LegPeriod<LegPeriodDates>> | undefined;
  dataStructure: LegPeriodDates;
  onValueChanged: (updatedValue: Partial<LegPeriodDates>, uuid: string) => void;
  isReadonly: boolean;
}

export function ScheduleLeg(scheduleLegProps: ScheduleLegProps) {
  const { title, addLine, deleteLine, data, dataStructure, onValueChanged, isReadonly } =
    scheduleLegProps;
  return (
    <div className="mx-2 my-1">
      <h5 className="mb-2 d-flex align-items-center justify-content-between">{title}</h5>
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th className="p-0" style={{ width: '32px' }}>
              <button
                className="btn btn-flat-primary btn-icon"
                aria-label="addPeriod"
                onClick={addLine}
                disabled={isReadonly}
              >
                <i className="icon">add</i>
              </button>
            </th>
            {Object.values(dataStructure).map(header => (
              <th key={header} className="minWidth85px width160px text-nowrap">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <If condition={!!data?.length}>
          <tbody>
            {data?.map(line => (
              <tr key={line.uuid}>
                <td className="p-0" style={{ width: '32px' }}>
                  <button
                    className="btn btn-flat-primary btn-icon"
                    onClick={() => deleteLine(line.uuid)}
                    disabled={isReadonly}
                  >
                    <i className="icon">delete_forever</i>
                  </button>
                </td>
                {Object.keys(dataStructure).map(key => (
                  <td key={key} className="p-0 minWidth85px width160px">
                    <FlowDatePicker
                      readOnly={isReadonly}
                      onChange={value => {
                        onValueChanged({ [key]: value }, line.uuid);
                      }}
                      maturities={[]}
                      date={line.dates[key as keyof LegPeriodDates]}
                      hideCalendarIcon
                      className="errorable-bloc"
                      inputClassName="form-control"
                      data-e2e={`schedule-${title
                        .split(' ')
                        .join('-')
                        .toLowerCase()}-${key.toLowerCase()}-${line.uuid}`}
                      popperPlacement="top"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </If>
      </table>
    </div>
  );
}
