import type { Action } from '@/bootstrap/actions';
import type { Thunk } from '@/bootstrap/thunks';
import type { DividendDates, DividendPeriod } from '../../../leg/product/elsProductOnyxModel';
import type { DividendFeature } from '../../../strategyOnyxModel';

export function createAddDividendSchedulePeriodThunk(
  strategyId: string,
  datesUpdate: DividendDates = {
    endDate: '',
    paymentDate: '',
    startDate: '',
    theoreticalPeriodPaymentDate: '',
  },
): Thunk {
  return function addDividendSchedulePeriodThunk(
    dispatch,
    getState,
    {
      selectors: { getFeature },
      services,
      actionCreators: {
        neos: { featureCrudActions },
      },
    },
  ) {
    const appState = getState();
    const feature: DividendFeature | undefined = getFeature(appState.featureState, {
      strategyId,
      type: 'DIVIDEND_COMPONENT',
    });

    const newPeriod: DividendPeriod = {
      uuid: services.idCreator.createId(),
      dates: datesUpdate,
    };

    const action: Action = featureCrudActions.upsert(
      {
        strategyId,
        type: 'DIVIDEND_COMPONENT',
      },
      {
        type: 'DIVIDEND_COMPONENT',
        strategyId,
        dividendSchedulePeriods: [...(feature?.dividendSchedulePeriods ?? []), newPeriod],
      },
    );

    dispatch(action);
  };
}
