import type { ExtendedDispatch, Thunks } from '@/bootstrap/thunks';
import type { OnyxBookingStep } from '@/neos/business/bookingSteps/bookingStepOnyxModel';
import type { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import type {
  OnyxAlert,
  OnyxNextUserActions,
  OnyxPreconfirmationEmail,
} from '../../../neos/business/neosOnyxModel';
import { alertReceived } from '../alert/alertReceived';
import { createIntegrateBlotterPreconfNotificationThunk } from '../blotter/blotterPreconf/integrateBlotterPreconfNotificationThunk';
import type { OnyxOrder } from '../order/orderOnyxModel';
import type { PreConfirmationStep } from '../preConfirmationStep/PreConfirmationStepModel';
import type { MailStatusType } from '../preconfirmation/shared/preconfirmationModel';
import type { PredealCheck } from '../predealCheck/predealCheckModel';
import type { Destination } from '../rfq/preconfirmationEmailPreview/preconfirmationEmailPreviewModel';
import type { OnyxRfq } from '../rfq/rfqOnyxModel';

export type NeosNotification =
  | NeosPredealChecksNotification
  | NeosBookingNotification
  | NeosRfqNotification
  | NeosWorkflowNotification
  | NeosErrorNotification
  | NeosBlotterRfqNotification
  | NeosAlertNotification
  | NeosOrderNotification
  | NeosPreconfirmationMailNotification
  | NeosPreConfirmationNotification
  | BlotterPreconfNotification;

export interface NeosPreConfirmationNotification {
  rfqId: string;
  type: 'preConfirmation';
  value: PreConfirmationStep;
}

export interface NeosPredealChecksNotification {
  rfqId: string;
  type: 'pdc';
  value: PredealCheck;
}

export interface NeosBookingNotification {
  rfqId: string;
  type: 'booking';
  value: OnyxBookingStep;
}

export interface NeosRfqNotification {
  rfqId: string;
  type: 'rfq';
  value: OnyxRfq;
}

export interface NeosWorkflowNotification {
  rfqId: string;
  type: 'workflow';
  value: OnyxNextUserActions;
}

export interface NeosErrorNotification {
  rfqId: string;
  type: 'error';
  value: string;
}

export interface NeosAlertNotification {
  rfqId: string;
  type: 'alert';
  value: OnyxAlert;
}

export interface NeosBlotterRfqNotification {
  rfqId: string;
  type: 'blotterView';
  value: OnyxRfq;
}

export interface NeosOrderNotification {
  rfqId: string;
  type: 'order';
  value: OnyxOrder;
}

export interface NeosPreconfirmationMailNotification {
  rfqId: string;
  type: 'preConfirmationMail';
  destination: Destination;
  value: OnyxPreconfirmationEmail;
}

export interface BlotterPreconf {
  externalMailStatus: MailStatusType | undefined;
  internalMailStatus: MailStatusType | undefined;
}

export interface BlotterPreconfNotification {
  type: 'preconfBlotterView';
  value: BlotterPreconf;
  rfqId: string;
}

function isPreConfirmationNotification(
  event: NeosNotification,
): event is NeosPreConfirmationNotification {
  return event.type === 'preConfirmation';
}

function isBlotterPreconfNotification(
  event: NeosNotification,
): event is BlotterPreconfNotification {
  return event.type === 'preconfBlotterView';
}

function isNeosPredealChecksNotification(
  event: NeosNotification,
): event is NeosPredealChecksNotification {
  return event.type === 'pdc';
}

function isNeosBookingNotification(event: NeosNotification): event is NeosBookingNotification {
  return event.type === 'booking';
}

function isNeosBlotterRfqNotification(
  event: NeosNotification,
): event is NeosBlotterRfqNotification {
  return event.type === 'blotterView';
}

function isNeosWorkflowNotification(event: NeosNotification): event is NeosWorkflowNotification {
  return event.type === 'workflow';
}

function isNeosRfqNotification(event: NeosNotification): event is NeosRfqNotification {
  return event.type === 'rfq';
}

function isNeosErrorNotification(event: NeosNotification): event is NeosErrorNotification {
  return event.type === 'error';
}

function isNeosAlertNotification(event: NeosNotification): event is NeosAlertNotification {
  return event.type === 'alert';
}

function isNeosOrderNotification(event: NeosNotification): event is NeosOrderNotification {
  return event.type === 'order';
}

function isPreConfirmationMail(
  event: NeosNotification,
): event is NeosPreconfirmationMailNotification {
  return event.type === 'preConfirmationMail';
}

export function listenToNeosNotifications(
  dispatch: ExtendedDispatch,
  {
    neos: {
      createPredealCheckNotificationReceivedThunk,
      createIntegrateReceivedBookingStepThunk,
      createNeosIntegrateRfqNotificationThunk,
      createAddBlotterRfqToBlotterThunk,
      createIntegrateNextUserActions,
      createAlertReceivedThunk,
      createIntegrateOrderNotificationThunk,
      createIntegratePreconfirmationThunk,
      createPreConfirmationNotificationReceivedThunk,
    },
    createWarningToasterThunk,
  }: Thunks,
  notification$: Observable<NeosNotification>,
): void {
  notification$
    .pipe(
      filter(isNeosAlertNotification),
      tap(({ value }) => alertReceived(value)),
    )
    .subscribe(({ value }) => dispatch(createAlertReceivedThunk(value)));

  notification$
    .pipe(filter(isPreConfirmationNotification))
    .subscribe(({ value, rfqId }) =>
      dispatch(createPreConfirmationNotificationReceivedThunk(rfqId, value)),
    );

  notification$
    .pipe(filter(isNeosPredealChecksNotification))
    .subscribe(({ value, rfqId }) =>
      dispatch(createPredealCheckNotificationReceivedThunk(rfqId, value)),
    );

  notification$
    .pipe(filter(isNeosErrorNotification))
    .subscribe(({ value }) => dispatch(createWarningToasterThunk({ message: value })));

  notification$
    .pipe(filter(isNeosRfqNotification))
    .subscribe(({ rfqId, value: onyxRfq }) =>
      dispatch(createNeosIntegrateRfqNotificationThunk(rfqId, onyxRfq)),
    );

  notification$
    .pipe(filter(isNeosBookingNotification))
    .subscribe(({ rfqId, value: bookingStep }) =>
      dispatch(createIntegrateReceivedBookingStepThunk(rfqId, bookingStep)),
    );

  notification$
    .pipe(filter(isNeosOrderNotification))
    .subscribe(({ rfqId, value: order }) =>
      dispatch(createIntegrateOrderNotificationThunk(rfqId, order)),
    );

  notification$
    .pipe(filter(isNeosBlotterRfqNotification))
    .subscribe(blotterRfq => dispatch(createAddBlotterRfqToBlotterThunk(blotterRfq.value)));

  notification$.pipe(filter(isNeosWorkflowNotification)).subscribe(({ rfqId, value }) => {
    dispatch(createIntegrateNextUserActions(rfqId, value));
  });

  notification$.pipe(filter(isPreConfirmationMail)).subscribe(({ rfqId, value }) => {
    dispatch(createIntegratePreconfirmationThunk(rfqId, value));
  });

  notification$.pipe(filter(isBlotterPreconfNotification)).subscribe(({ rfqId, value }) => {
    dispatch(createIntegrateBlotterPreconfNotificationThunk(rfqId, value));
  });
}
