import { services } from '@/bootstrap/services';
import type { Broker } from '@/neos/business/referenceData/brokers/brokersModel';
import type { Shortcut } from '@/neos/components/rfq/shortcuts/shared/ShortcutButton';
import { remove } from 'lodash';
import type { Counterpart, StrategyType } from '../../../business/neosModel';
import type { UserPreferencesAction } from './userPreferencesUiActions';
import type {
  BlotterDefaultTimerange,
  ColumnsDisplayToggle,
  CustomizableQuickButtonToggle,
  DisplayTimeZone,
} from './userPreferencesUiModel';
import { type UserPreferencesState, defaultUserPreferencesState } from './userPreferencesUiState';

export function userPreferencesReducer(
  state: UserPreferencesState = defaultUserPreferencesState,
  action: UserPreferencesAction,
): UserPreferencesState {
  switch (action.type) {
    case 'USER_PREFERENCES_INSERT_SEARCHED_BROKERS':
      return insertSearchedBrokersReducer(state, action.brokers);
    case 'USER_PREFERENCES_INSERT_SEARCHED_COUNTERPARTS':
      return insertSearchedCounterpartsReducer(state, action.counterparts);
    case 'USER_PREFERENCES_HIDDEN':
      return userPreferencesHiddenReducer(state);
    case 'USER_PREFERENCES_ADD_COUNTERPARTS_LOADING':
      return isAddCounterpartPreferenceButtonLoadingReducer(state, action.value);
    case 'USER_PREFERENCES_SEARCH_COUNTERPARTS_LOADING':
      return isSearchCounterpartPreferenceLoadingReducer(state, action.value);
    case 'USER_PREFERENCES_DELETE_COUNTERPARTS_LOADING':
      return isDeleteCounterpartPreferenceButtonLoadingReducer(state, action.value);
    case 'USER_PREFERENCES_DISPLAYED':
      return userPreferencesDisplayedReducer(state);
    case 'IBCHAT_SWITCHED':
      return ibChatShownReducer(state, action.toggle);
    case 'ALERT_SOUND_SWITCHED':
      return alertSoundReducer(state, action.toggle);
    case 'QUICK_BUTTONS_SWITCHED':
      return quickButtonsShownReducer(state, action.toggle);
    case 'QUICK_BUTTON_STRATEGY_TOGGLE_ACTION':
      return quickButtonStrategyToggleActionReducer(state, action.toggle);
    case 'QUICK_BUTTON_UNDERLYING_TOGGLE_ACTION':
      return quickButtonUnderlyingToggleActionReducer(state, action.toggle);
    case 'SALES_PRICES_TOGGLE_ACTION':
      return salesPricesToggleActionReducer(state, action.toggle);
    case 'DELTA_TOGGLE_ACTION':
      return deltaToggleActionReducer(state, action.toggle);
    case 'FAIR_GREEKS_TOGGLE_ACTION':
      return fairGreeksToggleActionReducer(state, action.toggle);
    case 'FAIR_PRICES_TOGGLE_ACTION':
      return fairPricesToggleActionReducer(state, action.toggle);
    case 'DISPLAY_TIMEZONE_CHANGED':
      return displayTimeZoneChangedReducer(state, action.displayTimeZone);
    case 'BLOTTER_DEFAULT_TIMERANGE_CHANGED':
      return blotterDefaultTimerangeChangedReducer(state, action.blotterDefaultTimerange);
    case 'NOTIFICATION_GROUP_SUBSCRIPTION_CHANGED':
      return notificationGroupSubscriptionChangedReducer(
        state,
        action.notificationGroupId,
        action.enable,
      );
    case 'USER_PREFERENCES_LOADED':
      return loadUserPreferencesReducer(action.userPreferences);
    case 'INDICATIVE_TRADABLE_CHANGED_ACTION':
      return indicativeTradableChangedReducer(state, action.isIndicativeTradableDisplayed);
    case 'VOL_BID_ASK_TOGGLE_ACTION':
      return volBidAskToggleActionReducer(state, action.toggle);
    case 'USER_PREFERENCES_UNDERLYING_SHORTCUTS_LIST_UPDATED':
      return updateUnderlyingShortcutsListActionReducer(state, action.underlyingsList);
    case 'USER_PREFERENCES_STRATEGIES_SHORTCUTS_LIST_UPDATED':
      return updateStrategiesShortcutsListActionReducer(state, action.strategiesList);
    default:
      return state;
  }
}

function indicativeTradableChangedReducer(
  state: UserPreferencesState,
  isIndicativeTradableDisplayed: boolean,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    isIndicativeTradableDisplayed,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function notificationGroupSubscriptionChangedReducer(
  state: UserPreferencesState,
  notificationGroupId: number,
  enable: boolean,
): UserPreferencesState {
  const notificationGroups = state.notificationGroups;

  const notificationGroupById = notificationGroups.filter(
    notificationGroup => notificationGroup.id == notificationGroupId,
  );

  if (notificationGroupById.length > 0) {
    remove(notificationGroups, item => item.id === notificationGroupId);
  } else {
    notificationGroups.push({ id: notificationGroupId, enable });
  }
  const userPreferences = {
    ...state,
    notificationGroups,
  };

  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function blotterDefaultTimerangeChangedReducer(
  state: UserPreferencesState,
  blotterDefaultTimerange: BlotterDefaultTimerange,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    blotterDefaultTimerange,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function displayTimeZoneChangedReducer(
  state: UserPreferencesState,
  displayTimeZone: DisplayTimeZone,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    displayTimeZone,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function insertSearchedBrokersReducer(
  state: UserPreferencesState,
  brokers: Broker[],
): UserPreferencesState {
  return {
    ...state,
    searchedBrokers: brokers,
  };
}

function insertSearchedCounterpartsReducer(
  state: UserPreferencesState,
  counterparts: Counterpart[],
): UserPreferencesState {
  return {
    ...state,
    searchedCounterparts: counterparts,
  };
}

function userPreferencesHiddenReducer(state: UserPreferencesState): UserPreferencesState {
  return {
    ...state,
    showUserPreferencesModal: false,
  };
}

function isDeleteCounterpartPreferenceButtonLoadingReducer(
  state: UserPreferencesState,
  value: boolean,
): UserPreferencesState {
  return {
    ...state,
    isDeleteCounterpartPreferenceButtonLoading: value,
  };
}

function isSearchCounterpartPreferenceLoadingReducer(
  state: UserPreferencesState,
  value: boolean,
): UserPreferencesState {
  return {
    ...state,
    isSearchCounterpartPreferenceLoading: value,
  };
}

function isAddCounterpartPreferenceButtonLoadingReducer(
  state: UserPreferencesState,
  value: boolean,
): UserPreferencesState {
  return {
    ...state,
    isAddCounterpartPreferenceButtonLoading: value,
  };
}

function userPreferencesDisplayedReducer(state: UserPreferencesState): UserPreferencesState {
  return {
    ...state,
    showUserPreferencesModal: true,
  };
}

function ibChatShownReducer(state: UserPreferencesState, toggle: boolean): UserPreferencesState {
  const userPreferences = {
    ...state,
    showIbChatSection: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function alertSoundReducer(state: UserPreferencesState, toggle: boolean): UserPreferencesState {
  const userPreferences = {
    ...state,
    isAlertSoundPlayed: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));
  return userPreferences;
}

function quickButtonsShownReducer(
  state: UserPreferencesState,
  toggle: boolean,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    showQuickButtonsSection: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function quickButtonStrategyToggleActionReducer(
  state: UserPreferencesState,
  toggle: CustomizableQuickButtonToggle,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    quickButtonStrategyToggle: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function updateUnderlyingShortcutsListActionReducer(
  state: UserPreferencesState,
  underlyingsShortcutsList: Shortcut<string>[],
): UserPreferencesState {
  const userPreferences = {
    ...state,
    underlyingsShortcutsList,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function updateStrategiesShortcutsListActionReducer(
  state: UserPreferencesState,
  strategiesShortcutsList: Shortcut<StrategyType>[],
): UserPreferencesState {
  const userPreferences = {
    ...state,
    strategiesShortcutsList,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function quickButtonUnderlyingToggleActionReducer(
  state: UserPreferencesState,
  toggle: CustomizableQuickButtonToggle,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    quickButtonUnderlyingToggle: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function salesPricesToggleActionReducer(
  state: UserPreferencesState,
  toggle: ColumnsDisplayToggle,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    salesPricesToggle: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function deltaToggleActionReducer(
  state: UserPreferencesState,
  toggle: ColumnsDisplayToggle,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    deltaToggle: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function volBidAskToggleActionReducer(
  state: UserPreferencesState,
  toggle: ColumnsDisplayToggle,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    volBidAskToggle: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function fairGreeksToggleActionReducer(
  state: UserPreferencesState,
  toggle: ColumnsDisplayToggle,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    fairGreeksToggle: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}

function loadUserPreferencesReducer(userPreferences: UserPreferencesState): UserPreferencesState {
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));
  return userPreferences;
}

function fairPricesToggleActionReducer(
  state: UserPreferencesState,
  toggle: ColumnsDisplayToggle,
): UserPreferencesState {
  const userPreferences = {
    ...state,
    fairPricesToggle: toggle,
  };
  services.localStorage.setLocalStorageItem('userPreferences', JSON.stringify(userPreferences));

  return userPreferences;
}
