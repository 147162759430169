import {
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
} from 'react';

export const NeosBlurInput = forwardRef<
  HTMLInputElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>((props, ref) => {
  const [state, setState] = useState(props.value);

  useEffect(() => {
    setState(props.value);
  }, [props.value]);

  return (
    <input
      ref={ref}
      {...props}
      className={`form-control ${props.className}`}
      value={state ?? ''}
      onChange={e => {
        setState(e?.target?.value);
      }}
      onBlur={e => props.onBlur?.(e)}
    />
  );
});
