import type {
  DividendDates,
  ElsRateLegPeriodDates,
  EquityLegPeriodDates,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';

export const equityLegPeriodDatesFields: EquityLegPeriodDates = {
  startDate: 'Start Date',
  endDate: 'End Date',
  paymentDate: 'Pay Date',
};

export const dividendLegPeriodDatesFields: DividendDates = {
  ...equityLegPeriodDatesFields,
  theoreticalPeriodPaymentDate: 'Theoretical Payment Date',
};

export const rateLegPeriodDatesFields: ElsRateLegPeriodDates = {
  fixingDate: 'Fixing Date',
  firstFixingDate: 'First Fixing Date',
  ...equityLegPeriodDatesFields,
};

export const rateLegPeriodDatesFieldsUntoggled: OmitSafe<ElsRateLegPeriodDates, 'firstFixingDate'> =
  {
    fixingDate: 'Fixing Date',
    ...equityLegPeriodDatesFields,
  };

export const clsRateLegPeriodDatesFields: Omit<ElsRateLegPeriodDates, 'firstFixingDate'> = {
  fixingDate: 'Fixing Date',
  ...equityLegPeriodDatesFields,
};
