import { selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { neosThunks } from '@/neos/business/thunks';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { FlowDatePickerWithOffset } from '@/neos/components/share/datePicker/FlowDatePickerWithOffset';
import { NumericInput } from '@/neos/components/share/numericInput';
import type { DateWithOffset } from '../../../../business/neosModel';

import { useAppSelector } from '@/bootstrap/hooks';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedStrategyValueDateAndOffsetModel } from './getSelectedStrategyValueDateAndOffsetModel';
import styles from './selectedStrategyValueDateStyle.module.scss';

export function SelectedStrategyValueDateAndOffset({ rfqId }: { rfqId: string }) {
  const valueDate = useSelector<AppState, DateWithOffset | undefined>(state =>
    getSelectedStrategyValueDateAndOffsetModel(state, rfqId, selectors),
  );
  const isStatusTraded = useAppSelector(
    state => selectors.getRfqData(state, rfqId).status === 'TRADED',
  );
  const selectedStrategyId = useSelector<AppState, string>(state =>
    selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId),
  );
  const toggle = useFeatureToggle('neos.cls.settlement.value.date.enabled');

  const datePickerWithOffsetToggle = useFeatureToggle('neos.datepicker.with.offset');

  const dispatch = useDispatch();

  function onOffsetChange(offset: number) {
    dispatch(neosThunks.createUpdateValueDateOffsetThunk(rfqId, selectedStrategyId, offset));
  }

  function onDateChange(date: string) {
    dispatch(neosThunks.createUpdateValueDateThunk(rfqId, selectedStrategyId, date));
  }

  if (!(valueDate && toggle)) {
    return null;
  }

  return datePickerWithOffsetToggle ? (
    <div className="d-flex align-items-center">
      <label className="my-0 me-2">Value Date</label>
      <FlowDatePickerWithOffset
        className={`rfq-header-value-date ${styles['w-125px']}`}
        readOnly={isStatusTraded}
        data-e2e="value-date"
        date={valueDate.value ?? ''}
        onChange={onDateChange}
        onOffsetChange={onOffsetChange}
        currentOffset={valueDate.offset}
      />
    </div>
  ) : (
    <>
      <div>
        <label className="mb-0 mx-1">Offset</label>
        <NumericInput
          className="d-inline-block date-value-offset-fixed-width"
          data-e2e="value-date-offset"
          value={valueDate.offset}
          onBlur={onOffsetChange}
        />
      </div>
      <div className="d-flex align-items-center">
        <label className="mb-0 mx-1 text-nowrap">Value Date</label>
        <FlowDatePicker
          className={`rfq-header-value-date ${styles['w-125px']}`}
          data-e2e="value-date"
          date={valueDate.value ?? ''}
          maturities={[]}
          readOnly
          onChange={() => void 0}
          hideCalendarIcon
        />
      </div>
    </>
  );
}
