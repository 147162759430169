import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';
import { BrokersPreferences } from '@/neos/components/userPreferences/brokers/BrokersPreferences';
import { CounterpartiesPreferences } from '@/neos/components/userPreferences/counterparties/CounterpartiesPreferences';
import { DefaultingPreferences } from '@/neos/components/userPreferences/defaulting/DefaultingPreferences';
import { BlotterPreferences } from '@/neos/components/userPreferences/general/BlotterPreferences';
import { GeneralPreferences } from '@/neos/components/userPreferences/general/GeneralPreferences';
import {
  type SettingTab,
  getUserPreferencesModel,
} from '@/neos/components/userPreferences/getUserPreferencesModel';
import { NotificationsGroups } from '@/neos/components/userPreferences/notifications/NotificationGroups';
import { PreconfirmationGroups } from '@/neos/components/userPreferences/preconfirmationsGroups/Preconfirmation';
import { RfqPreferences } from '@/neos/components/userPreferences/rfq/RfqPreferences';
import { StrategyGridDisplay } from '@/neos/components/userPreferences/rfq/strategyGridDisplay/StrategyGridDisplay';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export function UserPreferencesModal() {
  const {
    showModal,
    isPreconfToggled,
    isCounterpartiesFeatureToggled,
    isDefaultingFeatureToggled,
    isBrokersTabDisplayed,
    tabNames,
  } = useAppSelector(state => getUserPreferencesModel(state, selectors));

  const [activeTab, setActiveTab] = useState<SettingTab>('General');

  const dispatch = useDispatch();
  const hideModal = () => {
    dispatch(actionCreators.neos.createInsertSearchedCounterpartsAction([]));
    dispatch(actionCreators.neos.createInsertSearchedBrokersAction([]));
    dispatch(neosActionCreators.createUserPreferencesHiddenAction());
  };

  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={hideModal}
      onKeyPress={(event: any) => event.charCode === 13 && hideModal()}
    >
      <Modal.Header>
        <Modal.Title>Settings</Modal.Title>
        <button className="btn-close" type="button" aria-label="Close" onClick={hideModal} />
      </Modal.Header>
      <Modal.Body className="ps-3 pe-0" style={{ height: '500px' }}>
        <div className="row me-0 h-100">
          <div className="col-2 pe-0 border-end" style={{ minWidth: 'fit-content' }}>
            <ul className="nav nav-vertical-pills nav-hover flex-column">
              {tabNames.map(tabName => (
                <li key={tabName} className="nav-item">
                  {tabName === activeTab ? (
                    <span
                      data-e2e={`neos-user-preferences-active-menu-${tabName.replace(/\s/g, '')}`}
                      className="nav-link active"
                    >
                      {tabName}
                    </span>
                  ) : (
                    <a
                      className="nav-link"
                      data-e2e={`neos-user-preferences-menu-${tabName.replace(/\s/g, '')}`}
                      onClick={() => setActiveTab(tabName)}
                    >
                      {tabName}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="col h-100">
            {activeTab === 'General' && (
              <>
                <GeneralPreferences />
                <BlotterPreferences />
              </>
            )}
            {activeTab === 'RFQ' && (
              <>
                <RfqPreferences />
                <StrategyGridDisplay />
              </>
            )}
            {activeTab === 'Notifications' && <NotificationsGroups />}
            {isPreconfToggled && activeTab === 'Preconfirmations' && <PreconfirmationGroups />}
            {isCounterpartiesFeatureToggled && activeTab === 'Counterparties' && (
              <CounterpartiesPreferences />
            )}
            {isDefaultingFeatureToggled && activeTab === 'Defaulting' && <DefaultingPreferences />}
            {isBrokersTabDisplayed && activeTab === 'Brokers' && <BrokersPreferences />}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
