import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { isValidSecondaryWidgetStatus } from '@/neos/business/secondaryWidget/isValidSecondaryWidgetStatus';
import { keys, orderBy } from 'lodash';
import {
  type DisplayedColumnsForLeg,
  isClsProduct,
  isElsProduct,
  isListedExecutionProduct,
  isListedProduct,
  isOptionLike,
  isOptionProduct,
} from '../../../../neos/business/neosModel';

type StrategyDisplayedColumns = Omit<DisplayedColumnsForLeg, 'strategyType'>;
type MandatoryColumnsForAllStrategies = 'strategyType' | 'negoType' | 'size';
type MandatoryColumnsForAllStrategiesBesideELS = 'weight' | 'ratio';
type OtherColumn =
  | 'isMasterLeg'
  | 'warnings'
  | 'secondaryType'
  | 'secondaryTypeOnSecondaryRfq'
  | 'strategyLink'
  | 'legLink';

export type AllStrategyColumns =
  | keyof StrategyDisplayedColumns
  | MandatoryColumnsForAllStrategies
  | MandatoryColumnsForAllStrategiesBesideELS
  | OtherColumn;

export interface ColumnGridDefinition extends ColumnGridDefinitionBase {
  strategyIds: string[];
}

interface ColumnGridDefinitionBase {
  position: number;
  id: AllStrategyColumns;
  gridTemplateColumns: string;
}

type DisplayedColumnsForLegKeys = keyof DisplayedColumnsForLeg;

export function getMandatoryGridColumnsForAllStrategies(
  strategyIds: string[],
  flags: {
    shouldDisplayStrategyLink?: boolean;
    shouldDisplaySASecondaryType?: boolean;
    areSimilarActivitiesStrategies?: boolean;
    isElsRatioWeightToggleEnabled?: boolean;
    isElsEffectiveDateToggleEnabled?: boolean;
    isElsSettlementDateToggleEnabled?: boolean;
    isElsProduct?: boolean;
    isLoading?: boolean;
  },
): ColumnGridDefinition[] {
  const commonColumns: ColumnGridDefinition[] = [
    {
      position: 9,
      id: 'strategyType',
      gridTemplateColumns: 'minmax(120px, 240px)',
      strategyIds,
    },
    {
      position: 10,
      id: 'negoType',
      gridTemplateColumns: '50px',
      strategyIds,
    },
    {
      position: 32,
      id: 'size',
      gridTemplateColumns: 'minmax(80px, 115px)',
      strategyIds,
    },
  ];

  const weightAndRatioColumns: ColumnGridDefinition[] = [
    {
      position: 20,
      id: 'weight',
      gridTemplateColumns: 'minmax(45px, 60px)',
      strategyIds,
    },
    {
      position: 35,
      id: 'ratio',
      gridTemplateColumns: 'minmax(45px, 60px)',
      strategyIds,
    },
  ];

  if (!flags.isElsRatioWeightToggleEnabled || !flags.isElsProduct) {
    commonColumns.push(...weightAndRatioColumns);
  }

  if (flags.isElsEffectiveDateToggleEnabled && flags.isElsProduct) {
    commonColumns.push({
      position: 61,
      id: 'effectiveDate',
      gridTemplateColumns: '103px',
      strategyIds,
    });
  }

  if (flags.isElsSettlementDateToggleEnabled && flags.isElsProduct) {
    commonColumns.push({
      position: 64,
      id: 'settlementDate',
      gridTemplateColumns: '120px',
      strategyIds,
    });
  }

  const {
    shouldDisplaySASecondaryType,
    areSimilarActivitiesStrategies,
    shouldDisplayStrategyLink,
  } = flags;

  if (
    areSimilarActivitiesStrategies &&
    (shouldDisplaySASecondaryType || shouldDisplayStrategyLink)
  ) {
    return [getSecondaryTypeGridColumn(strategyIds), ...commonColumns];
  }

  if (shouldDisplayStrategyLink && !areSimilarActivitiesStrategies) {
    return [
      getStrategyLinkGridColumn(strategyIds),
      getLegLinkGridColumn(strategyIds),
      ...commonColumns,
    ];
  }

  return commonColumns;
}

function getStrategyLinkGridColumn(strategyIds: string[]): ColumnGridDefinition {
  return { position: 8, id: 'strategyLink', gridTemplateColumns: '70px', strategyIds };
}

function getLegLinkGridColumn(strategyIds: string[]): ColumnGridDefinition {
  return { position: 34, id: 'legLink', gridTemplateColumns: '70px', strategyIds };
}

function getMasterLegGridColumn(strategyIds: string[]): ColumnGridDefinition {
  return {
    position: 40,
    id: 'isMasterLeg',
    gridTemplateColumns: '32px',
    strategyIds,
  };
}

function getFlexOptionGridColumn(strategyIds: string[]): ColumnGridDefinition {
  return {
    position: 85,
    id: 'optionFlex',
    gridTemplateColumns: '32px',
    strategyIds,
  };
}

function getWarningsGridColumn(strategyIds: string[]): ColumnGridDefinition {
  return {
    position: 58,
    id: 'warnings',
    gridTemplateColumns: '25px',
    strategyIds,
  };
}

function getSecondaryTypeGridColumn(strategyIds: string[]): ColumnGridDefinition {
  return {
    position: 33.5,
    id: 'secondaryType',
    gridTemplateColumns: '180px',
    strategyIds,
  };
}

function getSecondaryTypeOnSecondaryRfqGridColumn(strategyIds: string[]): ColumnGridDefinition {
  return {
    position: 33.5,
    id: 'secondaryTypeOnSecondaryRfq',
    gridTemplateColumns: '32px',
    strategyIds,
  };
}

function getAllGridColumnsBase(withTop: boolean): ColumnGridDefinitionBase[] {
  const commonGridTemplateColumn = 'minmax(90px, 144px)';
  return [
    {
      position: 8,
      id: 'underlying',
      gridTemplateColumns: `minmax(${withTop ? '120' : '85'}px, 138px)`,
    },
    {
      position: 8.5,
      id: 'basketToggle',
      gridTemplateColumns: '60px',
    },
    {
      position: 25,
      id: 'lotSize',
      gridTemplateColumns: 'minmax(100px, 220px)',
    },
    {
      position: 32,
      id: 'elsType',
      gridTemplateColumns: '57px',
    },
    {
      position: 33,
      id: 'clsType',
      gridTemplateColumns: '58px',
    },
    {
      position: 59,
      id: 'swapCurrency',
      gridTemplateColumns: '80px',
    },
    {
      position: 61,
      id: 'startDate',
      gridTemplateColumns: '90px',
    },
    {
      position: 62,
      id: 'maturity',
      gridTemplateColumns: '90px',
    },
    {
      position: 63,
      id: 'futureMaturity',
      gridTemplateColumns: '90px',
    },
    {
      position: 64,
      id: 'clientPosition' as const,
      gridTemplateColumns: '33px',
    },
    {
      position: 66,
      id: 'expectedN',
      gridTemplateColumns: '68px',
    },
    {
      position: 70,
      id: 'optionStyle',
      gridTemplateColumns: '35px',
    },
    { id: 'observableType', position: 71, gridTemplateColumns: '35px' },
    {
      position: 80,
      id: 'optionType',
      gridTemplateColumns: '40px',
    },
    {
      position: 90,
      id: 'strike',
      gridTemplateColumns: commonGridTemplateColumn,
    },
    {
      position: 92,
      id: 'lowerStrike',
      gridTemplateColumns: commonGridTemplateColumn,
    },
    {
      position: 94,
      id: 'upperStrike',
      gridTemplateColumns: commonGridTemplateColumn,
    },
    {
      position: 100,
      id: 'forwardInterestRate',
      gridTemplateColumns: commonGridTemplateColumn,
    },
    {
      position: 110,
      id: 'forwardDrift',
      gridTemplateColumns: commonGridTemplateColumn,
    },
    {
      position: 121,
      id: 'accrual',
      gridTemplateColumns: '58px',
    },
    {
      position: 132,
      id: 'schedule',
      gridTemplateColumns: '32px',
    },
  ];
}

export interface DisplayedColumnsForLegsByStrategyId {
  [strategyId: string]: DisplayedColumnsForLeg[];
}

export function getColumnGridDefinitions(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
  allDisplayedColumnsForAllRfqStrategies: DisplayedColumnsForLegsByStrategyId,
  flags: {
    shouldDisplayStrategyLink?: boolean;
    shouldDisplaySASecondaryType?: boolean;
    areSimilarActivitiesStrategies?: boolean;
    isRfqWaitingForSecondaryIntegration?: boolean;
    isElsRatioWeightToggleEnabled?: boolean;
    isElsEffectiveDateToggleEnabled?: boolean;
    isElsSettlementDateToggleEnabled?: boolean;
    isElsProduct?: boolean;
  },
): ColumnGridDefinition[] {
  const result: ColumnGridDefinition[] = [];
  const allRfqStrategyIds = keys(allDisplayedColumnsForAllRfqStrategies);

  flags.isElsProduct = allRfqStrategyIds.some(stratId =>
    isElsProduct(selectors.getStrategyProduct(state, stratId, selectors)),
  );
  flags.isElsRatioWeightToggleEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.els.ratio.weight.enabled',
  );
  flags.isElsEffectiveDateToggleEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.els.effectiveDate.enabled',
  );
  flags.isElsSettlementDateToggleEnabled = selectors.isFeatureToggleEnabled(
    state,
    'neos.cls.settlement.value.date.enabled',
  );

  result.push(...getMandatoryGridColumnsForAllStrategies(allRfqStrategyIds, flags));

  manageLegMasterColumnDisplay(rfqId, state, selectors, allRfqStrategyIds, result);
  manageClientTaxRateColumnDisplay(rfqId, state, selectors, allRfqStrategyIds, result);
  manageNoTaxCollectionColumnDisplay(state, selectors, allRfqStrategyIds, result);
  manageFlexOptionColumnDisplay(rfqId, state, selectors, allRfqStrategyIds, result);
  manageWarningsColumnDisplay(rfqId, state, selectors, allRfqStrategyIds, result);
  manageStrikeDateColumnDisplay(state, selectors, allRfqStrategyIds, result);
  manageSecondaryTypeColumnDisplay(
    rfqId,
    state,
    selectors,
    allRfqStrategyIds,
    result,
    flags.areSimilarActivitiesStrategies,
  );
  manageSecondaryTypeOnSecondaryRfqColumnDisplay(
    rfqId,
    state,
    selectors,
    allRfqStrategyIds,
    result,
  );

  allRfqStrategyIds.forEach(strategyId => {
    const displayedColumnsForStrategy = allDisplayedColumnsForAllRfqStrategies[strategyId];

    displayedColumnsForStrategy.forEach(displayedColumnForLegStrategy => {
      const columnsToDisplay = getFilteredColumnsToDisplay(
        displayedColumnForLegStrategy,
        state,
        selectors,
        strategyId,
      );
      const underlyingIsDisplayedWithTop = selectors.hasACompositionLeg(
        state,
        strategyId,
        selectors,
      );

      const isUsListed = selectors.isUsListed(state, strategyId, selectors);
      const product = selectors.getStrategyProduct(state, strategyId, selectors);

      if (isOptionProduct(product) && isListedProduct(product)) {
        columnsToDisplay.push('observableType');
      }
      if (
        (selectors.isFeatureToggleEnabled(state, 'neos.els.schedule') && isElsProduct(product)) ||
        (selectors.isFeatureToggleEnabled(state, 'neos.cls.enabled') && isClsProduct(product))
      ) {
        columnsToDisplay.push('schedule');
      }

      if (
        selectors.isFeatureToggleEnabled(state, 'neos.els.schedule') &&
        selectors.isFeatureToggleEnabled(state, 'neos.els.basket.enabled') &&
        isElsProduct(product)
      ) {
        columnsToDisplay.push('basketToggle');
      }

      if (flags.isElsEffectiveDateToggleEnabled && isElsProduct(product)) {
        columnsToDisplay.push('effectiveDate');
      }

      if (flags.isElsSettlementDateToggleEnabled && isElsProduct(product)) {
        columnsToDisplay.push('settlementDate');
      }

      if (isUsListed) {
        columnsToDisplay.push('clientPosition');
      }

      const allGridColumnsBase = getAllGridColumnsBase(underlyingIsDisplayedWithTop);

      columnsToDisplay.forEach(columnKey => {
        if (columnGridDefinitionExists(result, columnKey)) {
          addStrategyTypeToExistingColumnGridDefinition(result, columnKey, strategyId);
        } else {
          addColumnGridDefinition(columnKey, strategyId, result, allGridColumnsBase);
        }
      });
    });
  });

  return orderBy(
    result,
    (columnGridDefinition: ColumnGridDefinition) => columnGridDefinition.position,
    ['asc'],
  );
}

function manageClientTaxRateColumnDisplay(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
  allRfqStrategyIds: string[],
  result: ColumnGridDefinition[],
) {
  const haveOneLegWithTaxCollectionInStrategyDefinition =
    selectors.selectHaveOneLegWithTaxCollectionInStrategyDefinition(
      state,
      allRfqStrategyIds,
      selectors,
    );
  const haveOneProductWithNoTaxCollection = selectors
    .getRfqProducts(state, rfqId, selectors)
    .some(product => product.noTaxCollection);

  if (haveOneLegWithTaxCollectionInStrategyDefinition && haveOneProductWithNoTaxCollection) {
    result.push({
      position: 57.5,
      id: 'clientTaxRate',
      gridTemplateColumns: '110px',
      strategyIds: allRfqStrategyIds,
    });
  }
}

function manageNoTaxCollectionColumnDisplay(
  state: AppState,
  selectors: Selectors,
  allRfqStrategyIds: string[],
  result: ColumnGridDefinition[],
) {
  const haveOneLegWithTaxCollectionInStrategyDefinition =
    selectors.selectHaveOneLegWithTaxCollectionInStrategyDefinition(
      state,
      allRfqStrategyIds,
      selectors,
    );

  if (haveOneLegWithTaxCollectionInStrategyDefinition) {
    result.push({
      position: 57,
      id: 'noTaxCollection',
      gridTemplateColumns: '60px',
      strategyIds: allRfqStrategyIds,
    });
  }
}
function manageLegMasterColumnDisplay(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
  allRfqStrategyIds: string[],
  result: ColumnGridDefinition[],
) {
  const rfqHasCompositionLeg = selectors.rfqHasCompositionLeg(selectors, state, rfqId);

  const isLegDetailsToggleOn = selectors.isLegDetailsToggleOn(state.ui, rfqId);
  if (isLegDetailsToggleOn && !rfqHasCompositionLeg) {
    result.push(getMasterLegGridColumn(allRfqStrategyIds));
  }
}

function manageFlexOptionColumnDisplay(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
  allRfqStrategyIds: string[],
  result: ColumnGridDefinition[],
) {
  const featureToggle = selectors.isFeatureToggleEnabled(
    state,
    'neos.workflow.flex.option.enabled',
  );

  const hasFlex = selectors
    .getRfqProducts(state, rfqId, selectors)
    .some(product => isOptionLike(product) && product.flex === 'FLEX');

  const hasListedStrategiesWithOptions = selectors
    .getRfqProducts(state, rfqId, selectors)
    .some(product => isOptionLike(product) && isListedExecutionProduct(product));

  if ((featureToggle && hasListedStrategiesWithOptions) || hasFlex) {
    result.push(getFlexOptionGridColumn(allRfqStrategyIds));
  }
}

function manageWarningsColumnDisplay(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
  allRfqStrategyIds: string[],
  result: ColumnGridDefinition[],
) {
  const { strategyIds, deltaHedgingStrategyIds } = selectors.getRfqData(state, rfqId);
  const hasWarnings = [...strategyIds, ...deltaHedgingStrategyIds].some(sId =>
    selectors.getStrategyWarnings(state, rfqId, sId, selectors).some(w => w.warning !== undefined),
  );
  if (hasWarnings) {
    result.push(getWarningsGridColumn(allRfqStrategyIds));
  }
}

function manageStrikeDateColumnDisplay(
  state: AppState,
  selectors: Selectors,
  allRfqStrategyIds: string[],
  result: ColumnGridDefinition[],
) {
  const isStrikeDateDisplayed = allRfqStrategyIds.some(strategyId => {
    const { strategyType } = selectors.getStrategyData(state, strategyId);
    const { legs } = selectors.getStrategyDefinition(state.referenceData, strategyType);
    return legs.some(leg => leg.strikeDate);
  });
  if (isStrikeDateDisplayed) {
    result.push({
      position: 60,
      id: 'strikeDate',
      gridTemplateColumns: '90px',
      strategyIds: allRfqStrategyIds,
    });
  }
}

function manageSecondaryTypeColumnDisplay(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
  allRfqStrategyIds: string[],
  result: ColumnGridDefinition[],
  areSimilarActivitiesStrategies: undefined | boolean,
) {
  const isRfqSecondaryWidgetToggleOn = selectors.isRfqSecondaryWidgetToggleOn(state.ui, rfqId);
  const isSecondaryRfq = selectors.isSecondaryRfq(state, rfqId);
  const isMixedRfq = selectors.isMixedRfq(state, rfqId);
  const { status } = selectors.getRfqData(state, rfqId);
  const isValidStatus = isValidSecondaryWidgetStatus(status);
  if (
    isRfqSecondaryWidgetToggleOn &&
    !isSecondaryRfq &&
    !isMixedRfq &&
    isValidStatus &&
    !areSimilarActivitiesStrategies
  ) {
    result.push(getSecondaryTypeGridColumn(allRfqStrategyIds));
  }
}

function manageSecondaryTypeOnSecondaryRfqColumnDisplay(
  rfqId: string,
  state: AppState,
  selectors: Selectors,
  allRfqStrategyIds: string[],
  result: ColumnGridDefinition[],
) {
  const isSecondaryOrMixedRfq = selectors.isSecondaryOrMixedRfq(state, rfqId);
  if (isSecondaryOrMixedRfq) {
    result.push(getSecondaryTypeOnSecondaryRfqGridColumn(allRfqStrategyIds));
  }
}

function getFilteredColumnsToDisplay(
  displayedColumnsForLegStrategy: DisplayedColumnsForLeg,
  state: AppState,
  selectors: Selectors,
  strategyId: string,
): DisplayedColumnsForLegKeys[] {
  return keys(displayedColumnsForLegStrategy)
    .map(key => key as DisplayedColumnsForLegKeys)
    .filter(
      key =>
        isNotStrategyTypeKey(key) &&
        isDisplayedColumnForLegStrategy(displayedColumnsForLegStrategy, key) &&
        isOptionOrOTCProductLotSizeColumn(state, selectors, key, strategyId),
    );
}

function isOptionOrOTCProductLotSizeColumn(
  state: AppState,
  neosSelectors: Selectors,
  key: DisplayedColumnsForLegKeys,
  strategyId: string,
): boolean {
  if (key === 'lotSize') {
    const { legIds } = neosSelectors.getStrategyData(state, strategyId);
    const { productId } = neosSelectors.getLegData(state, legIds[0]);
    const product = neosSelectors.getProduct(state, productId);
    return isOptionProduct(product) || product.negotiationMode !== 'OTC';
  }
  return true;
}

function isDisplayedColumnForLegStrategy(
  displayedColumnsForLegStrategy: DisplayedColumnsForLeg,
  key: DisplayedColumnsForLegKeys,
): boolean {
  return !!displayedColumnsForLegStrategy[key];
}

function isNotStrategyTypeKey(key: DisplayedColumnsForLegKeys): boolean {
  return key !== 'strategyType';
}

function columnGridDefinitionExists(
  result: ColumnGridDefinition[],
  displayedColumnsForLegKey: DisplayedColumnsForLegKeys,
): boolean {
  return result.findIndex(gridDefinition => gridDefinition.id === displayedColumnsForLegKey) !== -1;
}

function addColumnGridDefinition(
  columnKey: DisplayedColumnsForLegKeys,
  strategyId: string,
  result: ColumnGridDefinition[],
  allGridColumnsBase: ColumnGridDefinitionBase[],
) {
  const columnBase = allGridColumnsBase.find(gridDefinition => gridDefinition.id === columnKey);
  if (columnBase) {
    const columnGridDefinition = {
      ...columnBase,
      strategyIds: [strategyId],
    };

    result.push(columnGridDefinition);
  }
}

function addStrategyTypeToExistingColumnGridDefinition(
  result: ColumnGridDefinition[],
  key: DisplayedColumnsForLegKeys,
  strategyId: string,
) {
  const existingGridDefinitionIndex = result.findIndex(gridDefinition => gridDefinition.id === key);
  if (!result[existingGridDefinitionIndex].strategyIds.find(stratId => stratId === strategyId)) {
    result[existingGridDefinitionIndex].strategyIds.push(strategyId);
  }
}
