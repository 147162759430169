import { selectors } from '@/bootstrap/selectors';
import type { DividendDates } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel';
import { neosThunks } from '@/neos/business/thunks';
import { isElsProduct } from '../../../../../../../business/neosModel';

import { useAppSelector } from '@/bootstrap/hooks';
import { useDispatch } from 'react-redux';
import { dividendLegPeriodDatesFields } from '../ScheduleLegModel';
import { ScheduleLeg } from './ScheduleLeg';

type ScheduleDividendLegProps = {
  strategyId: string;
  rfqId: string;
};

export function ScheduleDividendLeg({ strategyId, rfqId }: ScheduleDividendLegProps) {
  const dispatch = useDispatch();
  const isReadonly = useAppSelector(appState =>
    selectors.isReadOnlyAtCurrentWorkflow(appState, strategyId, selectors),
  );
  const dividendData = useAppSelector(state =>
    selectors.selectScheduleDividendLegDates(state, strategyId, selectors),
  );

  function addDividendLegLine() {
    dispatch(neosThunks.createAddDividendSchedulePeriodThunk(strategyId));
  }

  function deleteDividendLegLine(uuid: string) {
    dispatch(
      neosThunks.createRemoveDividendSchedulePeriodThunk(
        { strategyId, type: 'DIVIDEND_COMPONENT' },
        uuid,
      ),
    );
  }

  function updateDividendLegField(newDate: Partial<DividendDates>, uuid: string) {
    const dispatchable = neosThunks.createUpdateDividendSchedulePeriodThunk(
      { strategyId, type: 'DIVIDEND_COMPONENT' },
      newDate,
      uuid,
    );
    dispatch(
      neosThunks.createChangeScheduleStartOrEndDateThunk(rfqId, strategyId, newDate, dispatchable),
    );
  }

  const product = useAppSelector(state =>
    selectors.getStrategyMasterProduct(state, strategyId, selectors),
  );

  if (isElsProduct(product) && product.elsType !== 'TRS') {
    return null;
  }

  return (
    <ScheduleLeg
      isReadonly={isReadonly}
      title="Dividend Leg"
      data={dividendData}
      dataStructure={dividendLegPeriodDatesFields}
      addLine={addDividendLegLine}
      deleteLine={deleteDividendLegLine}
      onValueChanged={updateDividendLegField}
    />
  );
}
