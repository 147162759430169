import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxPredealCheckKey } from '../../../neos/business/neosOnyxModel';

export function createIntegratePredealChecksRefreshThunk(
  rfqId: string,
  onyxPredealCheckKeys: OnyxPredealCheckKey[],
): Thunk {
  return function integratePredealChecksRefreshThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqData },
      actionCreators: {
        neos: { rfqDataCrudActions },
      },
      thunks: {
        neos: { createObsoletePredealChecksRemovedThunk },
      },
    },
  ) {
    const state = getState();

    const { predealCheckIds: oldPredealCheckIds } = getRfqData(state, rfqId);
    const newPredealCheckIds = onyxPredealCheckKeys.map(({ pdcUuid }) => pdcUuid);

    dispatch(rfqDataCrudActions.update(rfqId, { predealCheckIds: newPredealCheckIds }));
    dispatch(createObsoletePredealChecksRemovedThunk(rfqId, oldPredealCheckIds));
  };
}
