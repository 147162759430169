import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { type DateWithOffset, isListedProduct } from '../../../../../neos/business/neosModel';

export function getSelectedStrategyValueDateAndOffsetModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): DateWithOffset | undefined {
  const strategyId = selectors.getSelectedStrategyId(state.ui.selectedStrategy, rfqId);
  const product = selectors.getStrategyProduct(state, strategyId, selectors);
  const valueDate = selectors.getStrategyData(state, strategyId)?.valueDate;

  const isListed = isListedProduct(product);

  if (!isListed) {
    return valueDate;
  }
  return undefined;
}
