const englishFormat = new Intl.NumberFormat('en-EN', {
  maximumFractionDigits: 5,
});

export function formatNum(num?: number): string {
  return num === 0 ? '0' : num && !isNaN(num) ? englishFormat.format(num) : '';
}

export function fixPrecision(n?: number, precision: number = 5) {
  return n === 0 ? 0 : n && Number(n.toFixed(precision));
}

export function parseValidExcelNumber(input: string): number | null {
  const trimmedInput = input.trim();

  if (
    trimmedInput.includes('  ') ||
    trimmedInput.includes(',,') ||
    trimmedInput.includes('\t') ||
    trimmedInput.includes('..')
  ) {
    return null;
  }

  let cleanInput = trimmedInput.replace(' ', '');

  if (cleanInput.includes(',') && cleanInput.includes('.')) {
    const lastComma = cleanInput.lastIndexOf(',');
    const lastDot = cleanInput.lastIndexOf('.');
    if (lastComma > lastDot) {
      cleanInput = cleanInput.replace(/\./g, '').replace(',', '.');
    } else {
      cleanInput = cleanInput.replace(/,/g, '');
    }
  } else if (cleanInput.includes(',')) {
    cleanInput = cleanInput.replace(/,/g, '.');
  }

  const parsed = parseFloat(cleanInput);
  if (isNaN(parsed)) {
    return null;
  }

  return parsed;
}
