import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';

export function isNeosShowLegPrices(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): boolean {
  const { legIds } = selectors.getStrategyData(state, strategyId);
  if (legIds.length === 1) {
    return false;
  }
  const { status, source } = selectors.getRfqData(state, rfqId);

  if (services.orderOf(status).isAfterOrEqual('ORDER_CONFIRMED') || source === 'FIX_RFQHUB') {
    return true;
  }
  return selectors.isShowLegPrices(state.ui, strategyId);
}
