import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { ELSScheduleInputs } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/elsSchedule/ELSScheduleInputs';
import { ElsScheduleButtons } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/elsSchedule/ElsScheduleButtons';
import { ScheduleEquityLeg } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/legs/ScheduleEquityLeg';
import { ScheduleRateLeg } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/legs/ScheduleRateLeg';
import { ScheduleDividendLeg } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/legs/ScheduleDividendLeg';
import {
  rateLegPeriodDatesFields,
  rateLegPeriodDatesFieldsUntoggled,
} from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/ScheduleLegModel';
import { useFeatureToggle } from '@/neos/components/share/featureToggle/useFeatureToggle';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { If } from '@/neos/components/share/if/if';

type ElsScheduleProps = {
  rfqId: string;
  strategyId: string;
};

export function ElsSchedule({ rfqId, strategyId }: ElsScheduleProps) {
  const isScheduleUiToggleEnabled = useAppSelector(state =>
    selectors.isScheduleUiToggleEnabled(state, strategyId, selectors),
  );

  const product = useAppSelector(state =>
    selectors.getStrategyProduct(state, strategyId, selectors),
  );
  const productIsEls = isElsProduct(product);
  const isElsFirstFixingDateEnabled = useFeatureToggle('neos.els.firstfixingdate.enable');
  const hasRateOvernightFeature = useAppSelector(
    state =>
      selectors.getFeature(state.featureState, {
        strategyId,
        type: 'RATE_OVERNIGHT',
      })?.rateOvernight,
  );

  const ratesDateFields =
    isElsFirstFixingDateEnabled && hasRateOvernightFeature
      ? rateLegPeriodDatesFields
      : rateLegPeriodDatesFieldsUntoggled;

  const scheduleWarning = useAppSelector(state =>
    selectors.getElsScheduleWarning(state, rfqId, selectors),
  );

  const isDisplayed = isScheduleUiToggleEnabled && productIsEls;

  if (!isDisplayed) {
    return null;
  }

  const isDividendLegDisplayed = product.elsType === 'TRS';

  return (
    <div
      style={{ width: isDividendLegDisplayed ? '1450px' : '1200px', gridColumn: '2 / -2' }}
      className="p-1 px-2"
    >
      <div className="border p-3 h-100 overflow-auto card">
        <h4 className="d-inline-block p-1 m-0 mb-3">Schedule</h4>
        <div>
          <ELSScheduleInputs rfqId={rfqId} strategyId={strategyId} />
          <ElsScheduleButtons strategyId={strategyId} rfqId={rfqId} />

          <div className="d-flex justify-content-around">
            <ScheduleEquityLeg strategyId={strategyId} rfqId={rfqId} />
            <ScheduleRateLeg
              strategyId={strategyId}
              rfqId={rfqId}
              ratesDateFields={ratesDateFields}
            />
            <ScheduleDividendLeg strategyId={strategyId} rfqId={rfqId} />
          </div>
          <If condition={!!scheduleWarning}>
            <div className="alert alert-warning p-2">
              <i className="icon icon-sm ps-1 pe-2">warning</i>
              {scheduleWarning}
            </div>
          </If>
        </div>
      </div>
    </div>
  );
}
