import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { DisplayedColumnsForLeg, ObservationConvention } from '../../../neosModel';
import type {
  DefaultProductValuesForStrategy,
  FeatureDefinition,
  StrategyDefinition,
  StrategyDefinitionLeg,
  StrategyType,
} from '../strategyDefinitionModel';
import type {
  OnyxStrategyDefinition,
  OnyxStrategyDefinitionLeg,
  OnyxStrategyDefinitionProduct,
} from '../strategyDefinitionOnyxModel';
import {
  mapBarrierFeature,
  mapCapFloorFeature,
  mapDescriptionFeature,
  mapEndOfObservationFeature,
  mapEquityBulletFeature,
  mapExecFeesExtraFeature,
  mapForexTypeFeature,
  mapForwardStartFeature,
  mapInterestRateFeature,
  mapRateBulletFeature,
  mapRateOvernightFeature,
  mapResetFrequencyFeature,
  mapUpDownFeature,
} from './strategyDefinitionFeaturesMapper';

import { mapFromOnyxPeriod } from '@/neos/business/rfq/strategy/leg/product/mappers/specificProductMappers/mapFromOnyxPeriod';

export const fromStrategyDefinitionMappers = { mapFromOnyxStrategyDefinitions };

export function mapFromOnyxStrategyDefinitions(
  onyxStrategyDefinitions: OnyxStrategyDefinition[],
): StrategyDefinition[] {
  return onyxStrategyDefinitions.map((onyxStrategyDefinition: OnyxStrategyDefinition) => {
    const { legs } = onyxStrategyDefinition;

    const result: StrategyDefinition = {
      alias: onyxStrategyDefinition.alias,
      name: onyxStrategyDefinition.name,
      id: onyxStrategyDefinition.id,
      availableStrategyScopes: onyxStrategyDefinition.availableStrategyScopes,
      isSplittable: !!onyxStrategyDefinition.splittable,
      readOnlyMaster: !!onyxStrategyDefinition.readOnlyMaster,
      readOnlyNumberOfLegs: !!onyxStrategyDefinition.readOnlyNumberOfLegs,
      readOnlyProductNegotiationMode: !!onyxStrategyDefinition.readOnlyProductNegotiationMode,
      readOnlyProductClientPosition: !!onyxStrategyDefinition.readOnlyProductClientPosition,
      readOnlyProductOptionStyle: !!onyxStrategyDefinition.readOnlyProductOptionStyle,
      readOnlyProductOptionType: !!onyxStrategyDefinition.readOnlyProductOptionType,
      readOnlyProductSwapCurrency: !!onyxStrategyDefinition.readOnlyProductSwapCurrency,
      readOnlyProductSettlementDate: !!onyxStrategyDefinition.readOnlyProductSettlementDate,
      readOnlyProductStrikeDate: !!onyxStrategyDefinition.readOnlyProductStrikeDate,
      readOnlyProductRateCurve: !!onyxStrategyDefinition.readOnlyProductRateCurve,
      readOnlyProductRateTenor: !!onyxStrategyDefinition.readOnlyProductRateTenor,
      readOnlyProductExpectedN: !!onyxStrategyDefinition.readOnlyProductExpectedN,
      readOnlyProductElsType: !!onyxStrategyDefinition.readOnlyProductElsType,
      readOnlyProductClsType: !!onyxStrategyDefinition.readOnlyProductClsType,
      readOnlyProductAccrual: !!onyxStrategyDefinition.readOnlyProductAccrualType,
      sameNegotiatedSize: !!onyxStrategyDefinition.sameNegotiatedSize,
      sameProductDeliveryType: !!onyxStrategyDefinition.sameProductDeliveryType,
      sameProductLotSize: !!onyxStrategyDefinition.sameProductLotSize,
      sameProductMaturity: !!onyxStrategyDefinition.sameProductMaturity,
      sameProductClientPosition: !!onyxStrategyDefinition.sameProductClientPosition,
      sameProductOptionStyle: !!onyxStrategyDefinition.sameProductOptionStyle,
      sameProductOptionType: !!onyxStrategyDefinition.sameProductOptionType,
      sameProductOptionFlex: !!onyxStrategyDefinition.sameProductOptionFlex,
      sameProductStrike: !!onyxStrategyDefinition.sameProductStrike,
      sameProductUnderlying: !!onyxStrategyDefinition.sameProductUnderlying,
      sameProductNegotiationMode: !!onyxStrategyDefinition.sameProductNegotiationMode,
      sameProductSwapCurrency: !!onyxStrategyDefinition.sameProductSwapCurrency,
      sameProductSettlementDate: !!onyxStrategyDefinition.sameProductSettlementDate,
      sameProductStrikeDate: !!onyxStrategyDefinition.sameProductStrikeDate,
      sameProductExpectedN: !!onyxStrategyDefinition.sameProductExpectedN,
      sameProductForwardDrift: !!onyxStrategyDefinition.sameProductForwardDrift,
      sameProductForwardInterestRate: !!onyxStrategyDefinition.sameProductForwardInterestRate,
      sameProductRateCurve: !!onyxStrategyDefinition.sameProductRateCurve,
      sameProductRateTenor: !!onyxStrategyDefinition.sameProductRateTenor,
      sameProductElsType: !!onyxStrategyDefinition.sameProductElsType,
      sameProductClsType: !!onyxStrategyDefinition.sameProductClsType,
      sameProductAccrual: !!onyxStrategyDefinition.sameProductAccrualType,
      sameWeight: !!onyxStrategyDefinition.sameWeight,
      sameFixedDay: !!onyxStrategyDefinition.sameFixedDay,
      ratio: !!onyxStrategyDefinition.ratio,
      sameProductObservationShift: false,
      sameProductLockout: false,
      sameProductLookbackPeriod: false,
      sameProductPaymentDelay: false,
      sameProductCalculationMethod: false,
      sameFactorWeights: !!onyxStrategyDefinition.sameFactorWeights,
      hasACompositionLeg: hasACompositionLeg(onyxStrategyDefinition.id),
      availablePriceUnitTypes: onyxStrategyDefinition.availablePriceUnitTypes ?? [],
      priceUnitType: onyxStrategyDefinition.priceUnitType,
      legs: legs.map(onyxStrategyDefinitionLeg =>
        mapFromOnyxStrategyDefinitionLeg(onyxStrategyDefinition.id, onyxStrategyDefinitionLeg),
      ),
      sameGenerateFrom: !!onyxStrategyDefinition.sameGenerateFrom,
      sameEquityResetType: !!onyxStrategyDefinition.sameEquityResetType,
      sameRateReset: !!onyxStrategyDefinition.sameRateReset,
      sameResetMode: !!onyxStrategyDefinition.sameResetMode,
      sameBrokenPeriodType: !!onyxStrategyDefinition.sameBrokenPeriodType,
      sameWRateResetOnEach: !!onyxStrategyDefinition.sameWRateResetOnEach,
      sameConventionDay: !!onyxStrategyDefinition.sameConventionDay,
      sameEffectiveDate: !!onyxStrategyDefinition.sameEffectiveDate,
      sameEffectiveDateOffset: !!onyxStrategyDefinition.sameEffectiveDateOffset,
      sameRatePeriods: !!onyxStrategyDefinition.sameRatePeriods,
      sameEquityPeriods: !!onyxStrategyDefinition.sameEquityPeriods,
      sameRoleDefinition: !!onyxStrategyDefinition.sameRoleDefinition,
      sameCalculationAgent: !!onyxStrategyDefinition.sameCalculationAgent,
      sameDeterminingParty: !!onyxStrategyDefinition.sameDeterminingParty,
      sameHedgingParty: !!onyxStrategyDefinition.sameHedgingParty,
      sameRateSpreadAdjustment: !!onyxStrategyDefinition.sameRateSpreadAdjustment,
      sameDividendSpreadAdjustment: !!onyxStrategyDefinition.sameDividendSpreadAdjustment,
      sameDividendPriceType: !!onyxStrategyDefinition.sameDividendPriceType,
      sameLinearInterpolation: !!onyxStrategyDefinition.sameLinearInterpolation,
      sameLookthroughDR: !!onyxStrategyDefinition.sameLookthroughDR,
      sameRightToSubstituteScope: !!onyxStrategyDefinition.sameRightToSubstituteScope,
      sameRightToSubstituteConditions: !!onyxStrategyDefinition.sameRightToSubstituteConditions,
      sameRelatedExchange: !!onyxStrategyDefinition.sameRelatedExchange,
      sameTermNotice: !!onyxStrategyDefinition.sameTermNotice,
      sameClientTermNotice: !!onyxStrategyDefinition.sameClientTermNotice,
      sameDailyMinSize: !!onyxStrategyDefinition.sameDailyMinSize,
      sameDailyMaxSize: !!onyxStrategyDefinition.sameDailyMaxSize,
      sameSecondaryMarketAllowed: !!onyxStrategyDefinition.sameSecondaryMarketAllowed,
      sameComponentSecurityIndexAnnex: !!onyxStrategyDefinition.sameComponentSecurityIndexAnnex,
      sameBreakFeeElection: !!onyxStrategyDefinition.sameBreakFeeElection,
      sameBreakFeePeriods: !!onyxStrategyDefinition.sameBreakFeePeriods,
      sameBasisType: !!onyxStrategyDefinition.sameBasisType,
      sameCompoundRate: !!onyxStrategyDefinition.sameCompoundRate,
      sameElectionDate: !!onyxStrategyDefinition.sameElectionDate,
      sameElectionFee: !!onyxStrategyDefinition.sameElectionFee,
      sameSettlementMethodElection: !!onyxStrategyDefinition.sameSettlementMethodElection,
      sameTerminationConditions: !!onyxStrategyDefinition.sameTerminationConditions,
      sameTerminationRights: !!onyxStrategyDefinition.sameTerminationRights,
      sameTerminationType: !!onyxStrategyDefinition.sameTerminationType,
      sameValuationType: !!onyxStrategyDefinition.sameValuationType,
      sameDealType: !!onyxStrategyDefinition.sameDealType,
      sameDeclaredCashDiv: !!onyxStrategyDefinition.sameDeclaredCashDiv,
      sameLocalTaxes: !!onyxStrategyDefinition.sameLocalTaxes,
      sameSpecialDividends: !!onyxStrategyDefinition.sameSpecialDividends,
      sameRateFixingOffset: !!onyxStrategyDefinition.sameRateFixingOffset,
      sameBrokenPeriodPosition: !!onyxStrategyDefinition.sameBrokenPeriodPosition,
      sameExecFeesIn: !!onyxStrategyDefinition.sameExecFeesIn,
      sameExecFeesOut: !!onyxStrategyDefinition.sameExecFeesOut,
      sameDerogateRateFixingOffset: !!onyxStrategyDefinition.sameDerogateRateFixingOffset,
      sameObservableType: true,
      readOnlyObservableType: !!onyxStrategyDefinition.readOnlyObservableType,
      sameIsScheduleObsolete: !!onyxStrategyDefinition.sameIsScheduleObsolete,
      availableObservableTypes: onyxStrategyDefinition.availableObservableTypes ?? [],
      sameProductNoTaxCollection: !!onyxStrategyDefinition.sameProductNoTaxCollection,
      sameProductClientTaxRate: !!onyxStrategyDefinition.sameProductClientTaxRate,
      readOnlyProductNoTaxCollection: !!onyxStrategyDefinition.readOnlyProductNoTaxCollection,
      readOnlyProductClientTaxRate: !!onyxStrategyDefinition.readOnlyProductClientTaxRate,
    };
    return result;
  });
}

function hasACompositionLeg(strategyType: string): boolean {
  return ['VAR_DISPERSION', 'VOL_DISPERSION'].includes(strategyType);
}

function mapFromOnyxStrategyDefinitionLeg(
  strategyType: StrategyType,
  onyxStrategyDefinitionLeg: OnyxStrategyDefinitionLeg,
): StrategyDefinitionLeg {
  const {
    product,
    availableObservationConvention,
    upObservationConvention,
    availableSizeTypes,
    availableProductUnderlyingTypes,
    strikeDate,
  } = onyxStrategyDefinitionLeg;

  const defaultObservationConvention: ObservationConvention | undefined =
    convertNullToUndefined(upObservationConvention);

  const result: StrategyDefinitionLeg = {
    ...onyxStrategyDefinitionLeg,
    product: mapFromOnyxStrategyDefinitionProduct(product),
    features: mapToFeatures(product, defaultObservationConvention),
    displayedColumnsForLeg: mapToRequiredColumnsForStrategy(strategyType, product),
    availableObservationConventions: availableObservationConvention || [],
    availableSizeTypes: availableSizeTypes || [],
    availableProductUnderlyingTypes,
    strikeDate: !!strikeDate,
  };
  return result;
}

export function mapToFeatures(
  product: OnyxStrategyDefinitionProduct,
  defaultObservationConvention?: ObservationConvention,
): FeatureDefinition[] {
  return [
    ...mapUpDownFeature(product, defaultObservationConvention),
    ...mapCapFloorFeature(product),
    ...mapBarrierFeature(product),
    ...mapForwardStartFeature(product),
    ...mapExecFeesExtraFeature(product),
    ...mapRateOvernightFeature(product),
    ...mapRateBulletFeature(product),
    ...mapEquityBulletFeature(product),
    ...mapResetFrequencyFeature(product),
    ...mapInterestRateFeature(product),
    ...mapForexTypeFeature(product),
    ...mapDescriptionFeature(product),
    ...mapEndOfObservationFeature(product),
  ];
}

function mapToRequiredColumnsForStrategy(
  strategyType: StrategyType,
  onyxStrategyDefinitionProduct: OnyxStrategyDefinitionProduct,
): DisplayedColumnsForLeg {
  return {
    strategyType,
    strike: onyxStrategyDefinitionProduct.strike !== undefined,
    upperStrike: onyxStrategyDefinitionProduct.upperStrike !== undefined,
    lowerStrike: onyxStrategyDefinitionProduct.lowerStrike !== undefined,
    startDate: onyxStrategyDefinitionProduct.startDate !== undefined,
    swapCurrency: onyxStrategyDefinitionProduct.swapCurrency !== undefined,
    settlementDate: onyxStrategyDefinitionProduct.settlementDate !== undefined,
    strikeDate: onyxStrategyDefinitionProduct.strikeDate !== undefined,
    maturity: onyxStrategyDefinitionProduct.maturity !== undefined,
    effectiveDate: onyxStrategyDefinitionProduct.effectiveDate !== undefined,
    futureMaturity: onyxStrategyDefinitionProduct.futureMaturity !== undefined,
    expectedN: onyxStrategyDefinitionProduct.expectedN !== undefined,
    optionStyle: onyxStrategyDefinitionProduct.optionStyle !== undefined,
    optionFlex: onyxStrategyDefinitionProduct.optionFlexType !== undefined,
    optionType: onyxStrategyDefinitionProduct.optionType !== undefined,
    underlying: onyxStrategyDefinitionProduct.underlying !== undefined,
    lotSize: onyxStrategyDefinitionProduct.lotSize !== undefined,
    forwardDrift: onyxStrategyDefinitionProduct.forwardDrift !== undefined,
    forwardInterestRate: onyxStrategyDefinitionProduct.forwardInterestRate !== undefined,
    elsType: onyxStrategyDefinitionProduct.elsType !== undefined,
    clsType: onyxStrategyDefinitionProduct.clsType !== undefined,
    accrual: onyxStrategyDefinitionProduct.accrual !== undefined,
    noTaxCollection: onyxStrategyDefinitionProduct.noTaxCollection !== undefined,
    clientTaxRate: onyxStrategyDefinitionProduct.clientTaxRate !== undefined,
  };
}

// TODO: Check if we can link OnyxStrategyDefinitionProduct to DefaultProductValuesForStrategy
function mapFromOnyxStrategyDefinitionProduct(
  onyxStrategyDefinitionProduct: OnyxStrategyDefinitionProduct,
): DefaultProductValuesForStrategy {
  return {
    discriminator: onyxStrategyDefinitionProduct.discriminator,
    deliveryType: onyxStrategyDefinitionProduct.deliveryType ?? undefined,
    endOfObservation: onyxStrategyDefinitionProduct.endOfObservation ?? undefined,
    maturity: onyxStrategyDefinitionProduct.maturity ?? undefined,
    futureMaturity: onyxStrategyDefinitionProduct.futureMaturity ?? undefined,
    optionStyle: onyxStrategyDefinitionProduct.optionStyle ?? undefined,
    optionFlex: onyxStrategyDefinitionProduct.optionFlexType ?? undefined,
    optionType: onyxStrategyDefinitionProduct.optionType ?? undefined,
    strike: onyxStrategyDefinitionProduct.strike ?? undefined,
    upperStrike: onyxStrategyDefinitionProduct.upperStrike ?? undefined,
    lowerStrike: onyxStrategyDefinitionProduct.lowerStrike ?? undefined,
    startDate: onyxStrategyDefinitionProduct.startDate ?? undefined,
    swapCurrency: onyxStrategyDefinitionProduct.swapCurrency ?? undefined,
    settlementDate: onyxStrategyDefinitionProduct.settlementDate ?? undefined,
    strikeDate: onyxStrategyDefinitionProduct.strikeDate ?? undefined,
    underlying: onyxStrategyDefinitionProduct.underlying ?? undefined,
    lotSize: onyxStrategyDefinitionProduct.lotSize ?? undefined,
    negotiation: onyxStrategyDefinitionProduct.negotiation
      ? (onyxStrategyDefinitionProduct.negotiation.discriminator ?? undefined)
      : undefined,
    pointValue: onyxStrategyDefinitionProduct.pointValue ?? undefined,
    elsType: onyxStrategyDefinitionProduct.elsType ?? undefined,
    period: mapFromOnyxPeriod(onyxStrategyDefinitionProduct.period) ?? undefined,
    strikeType: onyxStrategyDefinitionProduct.strikeType ?? undefined,
    averageDisruptionDate: onyxStrategyDefinitionProduct.averageDisruptionDate ?? undefined,
    businessDayConvention: onyxStrategyDefinitionProduct.businessDayConvention ?? undefined,
    clsType: onyxStrategyDefinitionProduct.clsType ?? undefined,
    accrual: onyxStrategyDefinitionProduct.accrual ?? undefined,
    clientTaxRate: onyxStrategyDefinitionProduct.clientTaxRate ?? undefined,
    noTaxCollection: onyxStrategyDefinitionProduct.noTaxCollection ?? undefined,
    strikeTenor: onyxStrategyDefinitionProduct.strikeTenor ?? undefined,
  };
}
