import { selectors } from '@/bootstrap/selectors';
import type { Services } from '@/bootstrap/services';
import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import { isDefined } from '@/util/undefinedAndNull/isDefined';
import { uniq } from 'lodash';
import { defaultVersions } from '../../rfq/versions/versionsModel';
import { type ActorsSetup, DefaultingScope, type RfqData } from '../models';
import { buildParameterWithParentIdAsQuoteId } from '../quotes/quoteActionCreator';
import { DefaultingOptions } from '../rfqActions';
import type { SourceTarget } from './cloneRfqStrategies';

export function createCloneRfqThunk({
  rfqTargetId,
  rfqSourceId,
}: {
  rfqTargetId: string;
  rfqSourceId: string;
}): Thunk {
  return function cloneRfqThunk(
    dispatch,
    getState,
    {
      selectors: {
        getSesameId,
        getRfqData,
        getActorsSetup,
        getForexRates,
        getReferences,
        getHedges,
        getQuote,
        getSalesValo,
        isRfqMasterStrategyEls,
        isTrader,
      },
      thunks: {
        neos: {
          createCloneRfqStrategiesThunk,
          createEnsureCounterpartsLoadedThunk,
          createTrySelectCounterpart,
        },
      },
      actionCreators: {
        neos: {
          hedgeCrudActions,
          actorsSetupCrudActions,
          rfqDataCrudActions,
          versionsCrudActions,
          createDefaultQuotesAddedAction,
          forexRatesCrudActions,
          referenceCrudActions,
          strategyDataCrudActions,
          createContactsRequestedAction,
          createDefaultRfqRequestedAction,
        },
      },
      services: { idCreator },
    },
  ) {
    const state = getState();

    const isCurrentUserTrader = isTrader(state);
    const actorSetupToClone = getActorsSetup(state, rfqSourceId)!;
    const rfqDataToClone = getRfqData(state, rfqSourceId);
    const forexRatesToClone = getForexRates(state, rfqSourceId);
    const referencesToClone = getReferences(state, { rfqId: rfqSourceId });
    const hegdgesToClone = getHedges(state, { rfqId: rfqSourceId });
    const sesameId = getSesameId(state);
    const { unit, salesAskType } = getQuote(state, rfqDataToClone.quoteId);

    const strategyCloneIds = createStrategyCloneIds(rfqDataToClone, idCreator);
    const isRfqToCloneEls = isRfqMasterStrategyEls(state, rfqSourceId, selectors);
    const enrichScopes = [DefaultingScope.SALES_DIFFUSION, DefaultingScope.TRADE_DATE];
    const overrideScopes = [DefaultingScope.EXPECTED_N, DefaultingScope.TRADED_AWAY_FEEDBACK];

    if (isRfqToCloneEls) {
      overrideScopes.push(
        DefaultingScope.STRIKE_DATE,
        DefaultingScope.MATURITY_DATE,
        DefaultingScope.UNITS,
        DefaultingScope.BREAK_FEE,
      );
      enrichScopes.push(DefaultingScope.EFFECTIVE_DATE);
    }

    dispatch([
      createCloneRfqStrategiesThunk(rfqTargetId, strategyCloneIds),
      ...createActorsSetupActions(),
      rfqDataCrudActions.insert(
        rfqTargetId,
        createClonedRfqData(
          rfqTargetId,
          rfqDataToClone,
          strategyCloneIds.map(clone => clone.targetId),
          isCurrentUserTrader,
        ),
      ),
      versionsCrudActions.insert(rfqTargetId, defaultVersions()),
      forexRatesCrudActions.insert(rfqTargetId, { forexRates: [...forexRatesToClone] }),
      ...referencesToClone.map(reference =>
        referenceCrudActions.insert(
          { rfqId: rfqTargetId, underlyingId: reference.underlyingId },
          {
            ...reference,
            refAdjusted: undefined,
            executedSpot: undefined,
            isSpotConfirmed: undefined,
          },
        ),
      ),
      ...hegdgesToClone.map(hedge =>
        hedgeCrudActions.insert(
          {
            rfqId: rfqTargetId,
            underlyingId: hedge.underlyingId,
            deltaType: hedge.deltaType,
          },
          { ...hedge, delta: undefined, deltaUnit: undefined },
        ),
      ),
      ...strategyCloneIds.map(strategyId =>
        strategyDataCrudActions.update(strategyId.targetId, { traderId: undefined }),
      ),
      createDefaultQuotesAddedAction([
        buildParameterWithParentIdAsQuoteId('Rfq', rfqTargetId, unit, salesAskType),
      ]),
      createDefaultRfqRequestedAction(
        rfqTargetId,
        new DefaultingOptions({
          enrichScopes,
          overrideScopes,
        }),
        {
          success: {
            dispatchables: [
              createEnsureCounterpartsLoadedThunk(
                [
                  {
                    salesIds: uniq([sesameId, actorSetupToClone.salesValoId]).filter(isDefined),
                    rfqIds: [rfqTargetId],
                  },
                ],
                createTrySelectCounterpart(rfqTargetId, actorSetupToClone.counterpartId),
              ),
            ],
          },
        },
      ),
    ]);

    function createActorsSetupActions() {
      const didSalesInitChanged = actorSetupToClone.salesInitId !== sesameId;
      const salesValoEmail = actorSetupToClone.salesValoId
        ? getSalesValo(state.referenceData, actorSetupToClone.salesValoId)?.email
        : undefined;
      const actorSetupActions: ActionContainer[] = [
        actorsSetupCrudActions.insert(
          rfqTargetId,
          createClonedActorSetup(actorSetupToClone, sesameId),
        ),
      ];

      if (
        !didSalesInitChanged &&
        !actorSetupToClone.contacts?.length &&
        actorSetupToClone.counterpartId &&
        salesValoEmail
      ) {
        actorSetupActions.push(
          createContactsRequestedAction(
            rfqTargetId,
            actorSetupToClone.counterpartId,
            salesValoEmail,
          ),
        );
      }

      return actorSetupActions;
    }
  };
}

function createClonedRfqData(
  rfqId: string,
  rfqDataToClone: RfqData,
  clonedStrategyIds: string[],
  isTrader: boolean,
): RfqData {
  const {
    source,
    clientWay,
    crossBorder,
    pivotNotionalAmount,
    pivotNotionalCurrency,
    comment,
    notionalAmount,
    notionalCurrency,
    quoteType,
    priceRequestUTCDateAndTime,
    feedbackPriceUnit,
    notionalInSwapCurrencyCurrency,
    notionalInSwapCurrencyAmount,
    internal,
  } = rfqDataToClone;

  return {
    uuid: rfqId,
    source: 'VOICE',
    internal,
    clientWay,
    crossBorder,
    workflow: 'NEOS',
    pivotNotionalAmount,
    pivotNotionalCurrency,
    comment: source === 'VOICE' ? comment : '',
    clientComment: undefined,
    notionalInSwapCurrencyCurrency,
    notionalInSwapCurrencyAmount,
    notionalAmount,
    notionalCurrency,
    deltaHedgingStrategyIds: [],
    predealCheckIds: [],
    status: 'NEW',
    quoteId: rfqId,
    version: 0,
    strategyIds: clonedStrategyIds,
    otcPreAllocs: [],
    deltaOtcPreAllocs: [],
    listedPreAllocs: [],
    deltaListedPreAllocs: [],
    totalSalesMargin: undefined,
    totalSalesMarginEur: undefined,
    quoteType,
    priceRequestUTCDateAndTime,
    orderReceivedExpirationUTCTimestamp: undefined,
    rfqExpirationUTCTimestamp: undefined,
    creationTime: undefined,
    orderReceivedUTCTimestamp: undefined,
    hasToConfirmDelta: undefined,
    hasQuoteExpirationTime: false,
    quoteExpiryUTCTime: undefined,
    quoteValidityDuration: undefined,
    feedbackPriceValue: undefined,
    feedbackPriceUnit,
    feedbackPriceType: undefined,
    feedbackSpreadWidth: undefined,
    feedbackAxe: undefined,
    salesLink: undefined,
    salesMarginAmountEurByAllocation: undefined,
    initiatedByTrader: isTrader,
  };
}

function createStrategyCloneIds(
  rfqDataToClone: RfqData,
  idCreator: Services['idCreator'],
): SourceTarget[] {
  return rfqDataToClone.strategyIds.map(id => ({ sourceId: id, targetId: idCreator.createId() }));
}

function createClonedActorSetup(actorSetupToClone: ActorsSetup, salesInitId: string): ActorsSetup {
  return actorSetupToClone.salesInitId === salesInitId
    ? actorSetupToClone
    : {
        salesInitId,
        salesValoId: actorSetupToClone.salesValoId,
        salesGroupId: actorSetupToClone.salesGroupId,
        contacts: actorSetupToClone.contacts,
        counterpartId: undefined,
        eliotCode: undefined,
        mnemo: undefined,
        name: undefined,
        salesPhysicalLocation: undefined,
        salesInitCityLocation: undefined,
        salesDiffusionFeatureToggles: undefined,
      };
}
