import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '../../../neosModel';
import { DefaultingOptions } from '../../rfqActions';

export function createUpdateValueDateThunk(rfqId: string, strategyId: string, date: string): Thunk {
  return function updateValueDateThunk(
    dispatch,
    getState,
    { thunks, actionCreators: { neos: nacs }, selectors },
  ) {
    const state = getState();

    const elsFeatureToggleEnabled = selectors.isFeatureToggleEnabled(
      state,
      'neos.els.update.enabled',
    );
    const isEls = elsFeatureToggleEnabled && selectors.isElsStrategy(state, strategyId, selectors);

    const defaultingOptions = new DefaultingOptions({
      enrichScopes: [
        DefaultingScope.VALUE_DATE,
        DefaultingScope.SETTLEMENT_OTC,
        DefaultingScope.EFFECTIVE_DATE,
      ],
      overrideScopes: isEls ? [DefaultingScope.FIXED_DAY_TYPE] : [],
    });
    const products = selectors.getRfqProducts(state, rfqId, selectors);
    products.forEach(product => {
      dispatch(thunks.neos.changeClsIsScheduleObsoleteThunk(rfqId, product.uuid, true));
    });

    dispatch(
      nacs.strategyDataCrudActions.update(strategyId, {
        valueDate: { value: date, offset: undefined },
      }),
      nacs.createDefaultRfqRequestedAction(rfqId, defaultingOptions),
    );
  };
}
