import { serviceWorker } from '@/appServiceWorker';
import type { AppNotificationData } from '@/appServiceWorker/notificationModel';
import type { OnyxAlert } from './alertOnyxModel';
import { getAlertAssets } from './alertReceivedHelper';

export function alertReceived(alert: OnyxAlert) {
  if (alert.active) {
    const notificationData: AppNotificationData = {
      data: {
        type: 'NEOS_ALERT_CLICK',
        value: alert,
      },
      path: location.pathname + '#/neos/open/' + alert.rfqUuid,
    };
    const assets = getAlertAssets(alert);
    serviceWorker.showNotification(alert.message, notificationData, {
      requireInteraction: true,
      tag: alert.uuid,
      icon: assets?.icon,
      image: assets?.image,
    });
  } else {
    serviceWorker.hideNotification(alert.uuid);
  }
}
