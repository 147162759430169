import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { clsRateLegPeriodDatesFields } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/ScheduleLegModel';
import { CLSScheduleInputs } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/clsSchedule/CLSScheduleInputs';
import { ClsScheduleButtons } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/clsSchedule/ClsScheduleButtons';
import { ScheduleRateLeg } from '@/neos/components/rfq/strategies/strategy/features/elsClsSchedule/legs/ScheduleRateLeg';
import type { FC } from 'react';

type ClsScheduleProps = {
  rfqId: string;
  strategyId: string;
};

export const ClsSchedule: FC<ClsScheduleProps> = ({ rfqId, strategyId }) => {
  const isScheduleUiToggleEnabled = useAppSelector(state =>
    selectors.isScheduleUiToggleEnabled(state, strategyId, selectors),
  );
  const isClsStrategy = useAppSelector(state =>
    selectors.isClsStrategy(state, strategyId, selectors),
  );
  const isDisplayed = isScheduleUiToggleEnabled && isClsStrategy;

  if (!isDisplayed) {
    return null;
  }

  return (
    <div style={{ width: '1200px', gridColumn: '2 / -2' }} className="p-1 px-2">
      <div className="border p-3 h-100 overflow-auto card">
        <h4 className="d-inline-block p-1 m-0 mb-3">Schedule</h4>
        <div>
          <CLSScheduleInputs rfqId={rfqId} strategyId={strategyId} />
          <ClsScheduleButtons strategyId={strategyId} rfqId={rfqId} />

          <div className="d-flex justify-content-start">
            <ScheduleRateLeg
              title="Schedule Period"
              strategyId={strategyId}
              rfqId={rfqId}
              ratesDateFields={clsRateLegPeriodDatesFields}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
