import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

export function getIsPreConfToBeCompleted(
  state: AppState,
  rfqId: string,
  { getRfqData, getExternalEmailStatus, getInternalEmailStatus }: Selectors,
): boolean {
  const { status } = getRfqData(state, rfqId);

  const externalEmailStatus = getExternalEmailStatus(state, rfqId);
  const internalEmailStatus = getInternalEmailStatus(state, rfqId);

  return (
    status === 'TRADE_TO_BE_COMPLETED' &&
    ![externalEmailStatus, internalEmailStatus].some(s => s !== 'NOT_SENT')
  );
}
